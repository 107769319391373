import { uniqBy } from 'lodash-es';
import { ReactElement, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { PAGE_URL } from '../../../constants';
import {
  RootState, actions, MeetingSlot, MeetingHoldEventError, Meeting, MeetingUpdate, MeetingCreate,
  RecurringTimes, InvalidParticipantVotes, ThunkDispatchType, MeetingLeader, Leader,
  GlobalModalComponentName, PrivateExternalParticipant,
} from '../../../store';
import { fetchSchedulingPreferences, updateSchedulingPrefs } from '../../../store/schedule/actions';
import { useMountEffect } from '../../../utils/hooks';
import { ExcludedSlotInfo } from '../../../utils/scheduleUtils';
import MeetingSettings from './MeetingSettings';
import { useCabinetText } from '../../../CabinetContext';
import { router } from '../../../router';
import { CabModal, CabButton, CabTooltip, CabIcon } from '@CabComponents';
import { CabAvatar } from '@CabComponents/CabAvatar';
import LeaderEmailsModal from '../LeaderEmailsModal';
import { IoHelpCircleOutline } from 'react-icons/io5';
import { Box, Typography } from '@mui/material';
import {
  leaderSelectionUpdated, selectActiveCalendars, selectCurrentOrNewMeeting, selectSecondaryTimezones, selectTimezone,
  updateCalendarTimezone
} from '../../../store/scheduleUI';
import { useHandler } from '../../../store/hooks';
import { selectLeaderMap } from '../../../store/leaders/selectors';


export interface MeetingSettingsContainerProps {
  onDeleteSlots: (slots: MeetingSlot[]) => void;
  onUpdateMeetingName: (name: string) => void;
  meetingSlots: MeetingSlot[];
  onShowErrors: (show: boolean) => void;
  meetingErrors: MeetingHoldEventError[]
  onShare: () => void;
  onCancel: () => void;
  openMeetingSettings: boolean;
  slotsAreRecalculating: boolean;
  pollUpdateOpen: boolean;
  onPollUpdateAccept: () => void;
  onPollUpdateReject: () => void;
  invalidMeetingSlots: InvalidParticipantVotes;
  onExcludedSlotsCreated: (slots: ExcludedSlotInfo[]) => void;
  onEditSlot: (eventId: string, start: Date, end: Date, isExcluded: boolean) => void;
}


const MeetingSettingsContainer = ({
  meetingSlots, meetingErrors, openMeetingSettings, slotsAreRecalculating, pollUpdateOpen, invalidMeetingSlots,
  onShowErrors, onDeleteSlots, onShare, onUpdateMeetingName, onCancel, onPollUpdateAccept,
  onPollUpdateReject, onEditSlot, onExcludedSlotsCreated,
}: MeetingSettingsContainerProps): ReactElement => {

  const user = useSelector((state: RootState) => state.auth.user);
  const leaders = useSelector((state: RootState) => state.leaders);
  const leaderMap = useSelector(selectLeaderMap);
  const selectedLeaders = useSelector((state: RootState) => state.scheduleUI.selectedLeaderIds);
  const calendars = useSelector((state: RootState) => state.schedule.calendars);
  const meeting = useSelector(selectCurrentOrNewMeeting);
  const schedulingPrefs = useSelector((state: RootState) => state.schedule.schedulingPrefs);
  const zoomSettings = useSelector((state: RootState) => state.schedule.zoomSettings);
  const meetingRooms = useSelector((state: RootState) => state.schedule.meetingRooms);
  const presetLocations = useSelector((state: RootState) => state.schedule.presetLocations);
  const calendarTimezone = useSelector(selectTimezone);
  const secondaryTimezones = useSelector(selectSecondaryTimezones);
  const activeCalendars = useSelector(selectActiveCalendars);

  const [execsToUpdate, setExecsToUpdate] = useState<Leader[]>([]);
  const [execsToBeUpdatedOnMeeting, setExecsToBeUpdatedOnMeeting] = useState<Leader[]>([]);
  const [showConfirmCancelDialog, setShowConfirmCancelDialog] = useState(false);

  const navigate = router.navigate;
  const dispatch = useDispatch<ThunkDispatchType>();
  const handle = useHandler();

  const [dragTimeslotsText] = useCabinetText(['meeting-drag-timeslots']);
  const [deleteAttendeeId, setDeleteAttendeeId] = useState<number | null>(null);
  const [removeLeaderId, setRemoveLeaderId] = useState<number | null>(null);

  useMountEffect(() => {
    dispatch(fetchSchedulingPreferences());
  });

  const creatingMeeting = meeting != null && meeting?.id < 0;

  const currLeaders = useMemo(() => (
    leaders.leaders.filter(l => selectedLeaders.includes(l.id))
  ), [leaders, selectedLeaders]);

  const addedLeaders = useMemo(() => (
    execsToBeUpdatedOnMeeting.filter(l => !currLeaders.find(cl => cl.id === l.id))
  ), [currLeaders, execsToBeUpdatedOnMeeting]);

  const removedLeaders = useMemo(() => (
    currLeaders.filter(l => !execsToBeUpdatedOnMeeting.find(cl => cl.id === l.id))
  ), [currLeaders, execsToBeUpdatedOnMeeting]);

  const leaderCalendarOptions = useMemo(() => {
    return calendars.filter(cal => cal.canEdit
      && (selectedLeaders.some(l => cal.leaders.includes(l))
        // make sure user's own calendars are always an option
        || (user?.profile.user_leader && cal.leaders.includes(user?.profile.user_leader))
      ));
  }, [calendars, selectedLeaders, user?.profile.user_leader]);

  const confirmMeetingLeaderChange = () => {
    setExecsToBeUpdatedOnMeeting([]);
  };

  const cancelMeetingLeaderChange = () => {
    dispatch(leaderSelectionUpdated(execsToBeUpdatedOnMeeting.map(l => ({ leaderId: l.id, selected: false }))));
    setExecsToBeUpdatedOnMeeting([]);
  };

  // const handleLeaderSelectForOpenMeeting = (leaderIds: number[]) => {
  //   const leadersWithoutEmail = sortedLeaders.filter(l => Array.isArray(leaderIds) && leaderIds.includes(l.id))
  //     .filter(l => !l.email);
  //   const leadersWithEmail = sortedLeaders.filter(l => Array.isArray(leaderIds) && leaderIds.includes(l.id))
  //     .filter(l => l.email);

  //   if (leadersWithEmail.length > 0) {
  //     onLeaderSelect(leadersWithEmail.map(l => ({ leaderId: l.id, selected: true })));
  //   }

  //   if (leadersWithoutEmail.length > 0) {
  //     setExecsToUpdate(leadersWithoutEmail);
  //   }
  // };

  const handleUpdateLeaderEmails = async (updateLeaders: Leader[]) => {
    const leaderUpdatePromises: Promise<Leader | undefined>[] = [];
    updateLeaders.forEach(l => {
      const leaderUpdate = {id: l.id, email: l.email};
      leaderUpdatePromises.push(dispatch(actions.leaders.updateLeader(leaderUpdate, null)));
    });
    await Promise.resolve(leaderUpdatePromises);
  };

  const handleMeetingUpdate = useCallback(async (
    meetingUpdate: MeetingUpdate, files?: File[]
  ) => {
    if (meetingUpdate.id < 0) {
      // remove undefined values
      Object.keys(meetingUpdate).forEach((k) => {
        if (meetingUpdate[k as keyof MeetingUpdate] === undefined) {
          delete meetingUpdate[k as keyof MeetingUpdate];
        }
      });
      await dispatch(actions.schedule.updateNewMeeting(meetingUpdate));
    } else {
      await dispatch(actions.schedule.updateMeeting(meetingUpdate, [], files));
    }
    if (meetingUpdate.title) {
      onUpdateMeetingName(meetingUpdate.title);
    }
  }, [dispatch, onUpdateMeetingName]);

  const handleMeetingCreate = useCallback(async (
    mtgObj: MeetingCreate,
    files?:File[], 
    participants?: PrivateExternalParticipant[],
    meetingCreateLeaders?: MeetingLeader[],
  ) => {
    try {
      const res = await (dispatch(actions.schedule.createMeeting(
        mtgObj,
        meetingSlots.filter(slot => slot.id < 0),
        Object.values(mtgObj.questions).filter(question => question.id < 0),
        files, (participants || []), meetingCreateLeaders
      )) as unknown as Promise<{ meeting: Meeting }>);
 
      navigate(`${PAGE_URL.SCHEDULE}/${res.meeting.id}`, {replace: true});
      // setSaving(false);
    } catch (err) {
      alert(`Error: Meeting could not be created. Please try again
        later or email support@joincabinet.com for assistance.`);
      // setSaving(false);
    }
  }, [dispatch, meetingSlots, navigate]);

  const handleSaveRecurringTimes = useCallback((recurringTimes?: RecurringTimes) => {
    if (meeting?.id && meeting.id > 0) {
      dispatch(actions.schedule.updateMeeting({ id: meeting.id, recurring_times: recurringTimes || null}, []));
    } else {
      dispatch(actions.schedule.updateNewMeeting({ recurring_times: recurringTimes || undefined}));
    }
  }, [meeting?.id, dispatch]);

  const updateExecHoldNoticePref = (value: boolean) => {
    if (schedulingPrefs.user_prefs) {
      dispatch(updateSchedulingPrefs({...schedulingPrefs.user_prefs, silence_executive_hold_notice: value}));
    }
  };

  const deleteMeetingFile = useCallback((id: number, meetingId: number) => {
    dispatch(actions.schedule.deleteMeetingFile(id, meetingId));
  }, [dispatch]);

  const closeDeleteModal = () => {
    setDeleteAttendeeId(null);
    setRemoveLeaderId(null);
  };

  const handleDeleteAttendee = async (attendeeId: number) => {
    if (!meeting) return;
    closeDeleteModal();
    await dispatch(actions.schedule.deleteMeetingParticipant(attendeeId, meeting.id, meeting.id > 0));
  };

  const handleOpenUpgradeModal = () => dispatch(actions.globalModal.openModal(GlobalModalComponentName.CABINET_PROMO));

  const handleRemoveMeetingLeader = (meetingLeaderId: MeetingLeader['id']) => {
    if (!meeting) return;
    const leaderId = meeting.leader_info?.find(l => l.meeting_leader_id === meetingLeaderId)?.id;
    if (leaderId != null) {
      closeDeleteModal();

      const newLeaderIds = meeting.leader_info?.filter(l => l.id !== leaderId).map(l => l.id) || [];

      if (meeting.id < 0) {
        dispatch(actions.schedule.updateNewMeeting({ ...meeting, leaders: newLeaderIds }));
      } else {
        dispatch(actions.schedule.updateMeeting({ id: meeting.id, leaders: newLeaderIds }, []));
      }
    }
  };

  const handleAddZoom = () => {
    navigate(PAGE_URL.INTEGRATION_SETTINGS);
  };

  let holdCalendarOptions = activeCalendars.map(cal => {
    return {
      id: cal.id,
      calendar_id: cal.calendar_id || '',
      name: cal.summary || '',
      can_edit: cal.canEdit
    };
  }).filter(cal => cal.can_edit);

  // add current hold calendars to options and de-dupe
  if (meeting?.hold_calendar_info) {
    holdCalendarOptions = uniqBy([
      ...holdCalendarOptions,
      ...(meeting.hold_calendar_info || []).map(cal => ({
        id: cal.id, calendar_id: cal.calendar_id, name: cal.calendar_name || 'Unknown Calendar', can_edit: false,
      })),
    ], cal => cal.id);
  }

  const isOwner = user?.id === meeting?.create_user?.id;

  useEffect(() => {
    if (creatingMeeting) {
      const leadersWithoutEmail = leaders.leaders.filter(l => selectedLeaders.includes(l.id))
        .filter(l => !l.email);

      if (leadersWithoutEmail.length > 0) {
        setExecsToUpdate(leadersWithoutEmail);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creatingMeeting]);

  return (
    <>
      <MeetingSettings
        meetingSlots={meetingSlots}
        onDeleteSlots={onDeleteSlots}
        onRemoveLeader={setRemoveLeaderId}
        handleDeleteAttendee={setDeleteAttendeeId}
        calendarTimezone={calendarTimezone}
        secondaryTimezones={secondaryTimezones}
        holdCalendarOptions={holdCalendarOptions}
        leaderMap={leaderMap}
        meeting={meeting}
        showHolds={!!user?.features.EXEC_HOLDS}
        onShowErrors={onShowErrors}
        meetingErrors={meetingErrors}
        onSubmit={handleMeetingUpdate}
        onCreateNewMeeting={handleMeetingCreate}
        onShare={onShare}
        onCancel={onCancel}
        openUpgradeModal={handleOpenUpgradeModal}
        open={openMeetingSettings}
        schedulingPrefs={schedulingPrefs}
        isOwner={isOwner}
        updateExecHoldNoticePref={updateExecHoldNoticePref}
        slotsAreRecalculating={slotsAreRecalculating}
        pollUpdateOpen={pollUpdateOpen}
        pollUpdateHandlerAccept={onPollUpdateAccept}
        pollUpdateHandlerReject={onPollUpdateReject}
        invalidMeetingSlots={invalidMeetingSlots}
        dragTimeslotsText={dragTimeslotsText}
        handleSaveRecurringTimes={handleSaveRecurringTimes}
        onExcludedSlotsCreated={onExcludedSlotsCreated}
        onEditSlot={onEditSlot}
        deleteMeetingFile={deleteMeetingFile}
        features={user?.features}
        oauth_grant_details={user?.oauth_grant_details}
        user={user}
        zoomSettings={zoomSettings}
        onAddZoom={handleAddZoom}
        leaders={leaders}
        calendars={calendars}
        meetingRooms={meetingRooms}
        presetLocations={presetLocations}
        onCalendarTimezoneSelected={handle(updateCalendarTimezone)}
        leaderCalendarOptions={leaderCalendarOptions}
      />
      {meeting && (!!deleteAttendeeId || !!removeLeaderId) && (
        <CabModal
          open={!!deleteAttendeeId || !!removeLeaderId}
          title={'Remove Attendee'}
          onClose={closeDeleteModal}
          isAlert={true}
          noFullScreen={true}
          text={meeting.is_poll 
            ? 'All votes for times that this person submitted will be removed.' 
            : 'Are you sure you want to remove this person from the meeting?'}
          actionButtons={
            <>
              <CabButton buttonType='secondary' onClick={closeDeleteModal}>
                Cancel
              </CabButton>
              <CabButton onClick={ deleteAttendeeId ? () => handleDeleteAttendee(deleteAttendeeId)
                : removeLeaderId ? () => handleRemoveMeetingLeader(removeLeaderId) : undefined} 
              color="error"
              >
                Delete
              </CabButton>
            </>
          }
        />
      )}

      {execsToUpdate.length > 0 && (
        <>
          <LeaderEmailsModal
            isOpen={execsToUpdate.length > 0}
            leaders={execsToUpdate}
            onEmailsSubmitted={async (leadersToUpdate) => {
              await handleUpdateLeaderEmails(leadersToUpdate);
              dispatch(leaderSelectionUpdated(
                Array.from(new Set([...selectedLeaders, ...leadersToUpdate.map(l => l.id)]))
                  .map(lId => ({ leaderId: lId, selected: true }))
              ));
              setExecsToUpdate([]);
            }}
            onCancel={() => {
              setShowConfirmCancelDialog(true);
            }}
          />
          <CabModal
            open={showConfirmCancelDialog}
            onClose={() => setShowConfirmCancelDialog(false)}
            isAlert
            title="Are you sure?"
            text="Any executives without an email will be removed from this meeting."
            actionButtons={<>
              <CabButton buttonType='tertiary' color='primary' onClick={() => setShowConfirmCancelDialog(false)}>
                Add Emails
              </CabButton>
              <CabButton onClick={() => {
                dispatch(leaderSelectionUpdated(execsToUpdate.map(l => ({ leaderId: l.id, selected: false }))));
                setExecsToUpdate([]);
                setShowConfirmCancelDialog(false);
              }}>
                Remove Executives
              </CabButton>
            </>}
          />
        </>
      )}

      {execsToBeUpdatedOnMeeting.length > 0 && (addedLeaders.length > 0 || removedLeaders.length > 0) && (
        <ConfirmMeetingLeaderChangeModal
          open={execsToBeUpdatedOnMeeting.length > 0}
          onCancel={cancelMeetingLeaderChange}
          onConfirm={confirmMeetingLeaderChange}
          addedLeaders={addedLeaders}
          removedLeaders={removedLeaders}
        />
      )}
    </>
  );
};

export default memo(MeetingSettingsContainer);


const ConfirmMeetingLeaderChangeModal = ({ open, onCancel, onConfirm, addedLeaders, removedLeaders }: {
  open: boolean; onCancel: () => void; onConfirm: () => void; addedLeaders: Leader[]; removedLeaders: Leader[]
}) => {

  return (
    <CabModal
      open={open}
      onClose={onCancel}
      isAlert
      title="Change Meeting Teammates"
      text="The following changes will be made to your meeting:"
      actionButtons={<Box width="100%" display="flex" justifyContent="space-between" padding={1}>
        <CabTooltip
          title="Don't want to update this meeting? Close the meeting panel on the right before changing teammates"
          wrapWithSpan
          placement="bottom-start"
        >
          <CabIcon Icon={IoHelpCircleOutline} size="large" />
        </CabTooltip>
        <Box display="flex" gap={1}>
          <CabButton buttonType='tertiary' color='primary' onClick={onCancel}>
            No
          </CabButton>
          <CabButton onClick={onConfirm}>
            Yes
          </CabButton>
        </Box>
      </Box>}
    >
      {removedLeaders.length > 0 && (
        <>
          <Typography fontWeight="bold" marginTop={2} marginBottom={1}>Teammates Removed</Typography>
          <Box display="flex" flexDirection="column" gap={1}>
            {removedLeaders.map(l => (
              <Box display="flex" key={l.id} alignItems="center" gap={1}>
                <CabAvatar 
                  name={`${l.first_name} ${l.last_name}`}
                  src={l.pic_url}
                  color={l.color}
                  size="medium"
                />
                <Typography>{l.first_name} {l.last_name}</Typography>
              </Box>
            ))}
          </Box>
        </>
      )}
      {addedLeaders.length > 0 && (
        <>
          <Typography fontWeight="bold" marginTop={2} marginBottom={1}>Teammates Added</Typography>
          <Box display="flex" flexDirection="column" gap={1}>
            {addedLeaders.map(l => (
              <Box display="flex" key={l.id} alignItems="center" gap={1}>
                <CabAvatar 
                  name={`${l.first_name} ${l.last_name}`}
                  src={l.pic_url}
                  color={l.color}
                  size="medium"
                />
                <Typography>{l.first_name} {l.last_name}</Typography>
              </Box>
            ))}
          </Box>
        </>
      )}
    </CabModal>
  );
};
