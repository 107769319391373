import { useSelector } from "react-redux";
import SidePanel from "./SidePanel";
import { RootState } from "../../../store";
import { useMemo } from "react";
import {
  additionalCalendarSelectionUpdated,
  hiddenCalendarIdsUpdated,
  leaderSelectionUpdated, selectMiddleOfInterval, selectSelectedAdditionalCalendars, updateDateRange
} from "../../../store/scheduleUI";
import { useHandler } from "../../../store/hooks";
import { selectOrderedSchedulingLeaders } from "../../../store/leaders/selectors";
import { selectLeaderIdCalendarMap, selectLeaderIdPrimaryCalendarMap } from "../../../store/schedule/selectors";


const SidePanelContainer = ({ open, onCancel }: {
  open: boolean;
  onCancel: () => void;
}) => {
  const schedulingLeaders = useSelector(selectOrderedSchedulingLeaders);
  const selectedAdditionalCalendars = useSelector(selectSelectedAdditionalCalendars);
  const middleOfInterval = useSelector(selectMiddleOfInterval);
  const hiddenCalendarIds = useSelector((state: RootState) => state.scheduleUI.hiddenCalendarIds);
  const leaderIdCalendarMap = useSelector(selectLeaderIdCalendarMap);
  const leaderIdPrimaryCalendarMap = useSelector(selectLeaderIdPrimaryCalendarMap);
  const calendarView = useSelector((state: RootState) => state.scheduleUI.calendarView);
  const calendarsLoaded = useSelector((state: RootState) => state.schedule.calendarsLoaded);
  const meetingsLoaded = useSelector((state: RootState) => state.schedule.meetingsLoaded);
  const selectedLeaders = useSelector((state: RootState) => state.scheduleUI.selectedLeaderIds);
  const weekStartDay = useSelector((state: RootState) => state.schedule.schedulingPrefs.user_prefs?.week_start_day);

  const allLoaded = calendarsLoaded && meetingsLoaded;

  const handle = useHandler();

  const additionalCalendars = useMemo(() => selectedAdditionalCalendars.map(cal => ({
    ...cal,
    title: cal.additionalCalendarEmail?.name || cal.summary || cal.owner_email || '',
    subtitle: cal.owner_email || '',
    canEdit: !!cal.additionalCalendarEmail || cal.canEdit,
  })), [selectedAdditionalCalendars]);

  return (
    <SidePanel
      open={open}
      onCancel={onCancel}
      leaders={schedulingLeaders}
      additionalCalendars={additionalCalendars}
      selectedLeaders={selectedLeaders}
      onLeaderSelect={handle(leaderSelectionUpdated)}
      middleOfInterval={middleOfInterval}
      onUpdateDateRange={handle(updateDateRange)}
      hiddenCalendarIds={hiddenCalendarIds}
      onToggleCalendarVisibility={handle(hiddenCalendarIdsUpdated)}
      leaderIdCalendarMap={leaderIdCalendarMap}
      leaderIdPrimaryCalendarMap={leaderIdPrimaryCalendarMap}
      calendarView={calendarView}
      allLoaded={allLoaded}
      onCheckAdditionalCalendar={handle(additionalCalendarSelectionUpdated)}
      weekStartDay={weekStartDay}
    />
  );
};

export default SidePanelContainer;
