import { useCallback, useMemo, useState } from "react";
import { Box, Menu, MenuItem, ListItemText, Typography, SxProps } from "@mui/material";
import { ReactElement } from "react";
import { View as RBCView, Views as RBCViews } from 'react-big-calendar';
import { IoChevronBack, IoChevronDown, IoChevronForward, IoLockClosedOutline } from 'react-icons/io5';
import { CabButton, CabCheckbox, CabIcon, CabTooltip } from "@CabComponents";
import CreateMeetingButton from "../../../components/Schedule/CreateMeetingButton/CreateMeetingButton";
import colors from "../../../colors";
import { getPickerDay, getWeekRange, TimeZone } from "../../../utils/scheduleUtils";
import { DateTime, Settings } from "luxon";
import { HEADER_HEIGHT } from "../../../constants";
import { FiSidebar } from "react-icons/fi";
import UserPrefCabDatePicker from "@CabComponents/CabDatePicker/UserPrefDatePicker";
import { WeekStartDay } from "../../../store";

export interface ScheduleHeaderProps {
  creatingMeeting: boolean;
  currentMeetingId?: number;
  onCreateMeeting: () => void;
  onCreateReusableMeeting: () => void;
  onCreatePoll: () => void;
  calendarTimezoneSelected: TimeZone | undefined;
  onDateChange: (start: DateTime, end: DateTime) => void;
  currentDateRangeInfo: { start: DateTime, end: DateTime };
  onToggleSidePanel: () => void;
  onToggleMultiTimezone?: () => Promise<void>;
  onToggleGranularTimeSelection?: () => Promise<void>;
  onToggleShowAllRecurringSlots?: () => void;
  onToggleCanceledDeclinedMeetings?: () => void;
  hasMultiTimezone?: boolean;
  showCanceledDeclinedMeetings?: boolean;
  hasMultiLeaderCalendarView?: boolean;
  showAllRecurringSlots?: boolean;
  granularTimeSelection?: boolean;
  openUpgradeModal?: () => void;
  showSecondaryTimezone?: boolean;
  calendarView: RBCView;
  onCalendarViewChange: (view: RBCView) => void;
  showPendingSlots: boolean;
  condensePendingSlots: boolean;
  showAllDayEvents: boolean;
  showMultiLeaderColumns: boolean;
  onToggleShowPendingSlots: () => void;
  onToggleCondensePendingSlots: () => void;
  onToggleShowAllDayEvents: () => void;
  onToggleShowMultiLeaderColumns: () => void;
  weekStartDay?: WeekStartDay | null;
}


const ScheduleHeader = ({ creatingMeeting, 
  currentMeetingId, onCreateMeeting, onCreateReusableMeeting, onCreatePoll, calendarTimezoneSelected, onDateChange,
  currentDateRangeInfo, onToggleSidePanel, onToggleMultiTimezone, onToggleGranularTimeSelection,
  onToggleShowAllRecurringSlots, onToggleCanceledDeclinedMeetings, hasMultiTimezone, showCanceledDeclinedMeetings,
  hasMultiLeaderCalendarView, showAllRecurringSlots, granularTimeSelection, openUpgradeModal, showSecondaryTimezone,
  calendarView, onCalendarViewChange, showPendingSlots, condensePendingSlots, showAllDayEvents, showMultiLeaderColumns,
  onToggleShowPendingSlots, onToggleCondensePendingSlots, onToggleShowAllDayEvents, onToggleShowMultiLeaderColumns,
  weekStartDay
}: ScheduleHeaderProps): ReactElement => {
  const [loadingViewOptions, setLoadingViewOptions] = useState(false);
  const [viewOptionsAnchorEl, setViewOptionsAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(viewOptionsAnchorEl);

  const timezone = useMemo(() => (
    calendarTimezoneSelected?.name || ''
  ), [calendarTimezoneSelected?.name]);

  const { getNow } = useMemo(() => {
    Settings.defaultZone = DateTime.local().setZone(timezone).zoneName || "";
    return { getNow: () => DateTime.local().toJSDate() };
  }, [timezone]);

  const middleOfInterval = useMemo(() => {
    let date = currentDateRangeInfo 
      ? getPickerDay(currentDateRangeInfo.start.toISO() || '')
      : DateTime.local();
    let dayDiff = 0;
    if (calendarView === RBCViews.WORK_WEEK || calendarView === RBCViews.WEEK) {
      const { start } = getWeekRange(date, weekStartDay);
      date = start;
      dayDiff = 2;
    }

    const midOfInterval = new Date(date.setZone(timezone).toJSDate());
    midOfInterval.setDate(midOfInterval.getDate() + dayDiff);

    return midOfInterval;
  }, [calendarView, currentDateRangeInfo, timezone, weekStartDay]);

  const onPickerDateChange = useCallback((newDateStr: string | null) => {
    if (newDateStr) {
      const newDate = DateTime.fromISO(newDateStr, {zone: timezone});

      if (calendarView === RBCViews.WEEK || calendarView === RBCViews.WORK_WEEK) {
        const {start, end } = getWeekRange(newDate, weekStartDay);
        
        onDateChange(start, end);
      } else if (calendarView === RBCViews.DAY) {
        const startDay = newDate.startOf('day');
        const endDay = newDate.endOf('day');
        onDateChange(startDay, endDay);
      }
    }
  }, [calendarView, onDateChange, timezone, weekStartDay]);

  const next = () => {
    const dateRangeType = calendarView === RBCViews.WEEK || calendarView === RBCViews.WORK_WEEK ? 'weeks' : 'days';
    const newDate = DateTime.fromISO(currentDateRangeInfo.start.toISO() || '', {zone: timezone})
      .plus({[dateRangeType]: 1});

    onPickerDateChange(dateRangeType === 'weeks'
      ? newDate.plus({days: 1}).toISO()
      : newDate.toISO());
  };

  const prev = () => {
    const dateRangeType = calendarView === RBCViews.WEEK || calendarView === RBCViews.WORK_WEEK ? 'weeks' : 'days';
    const newDate = currentDateRangeInfo.start.minus({[dateRangeType]: 1}); 

    onPickerDateChange(newDate.toISO());
  };

  const handleChangeView = useCallback((view: RBCView) => {
    if (view === RBCViews.DAY) {
      const startDate = DateTime.fromISO(getNow().toISOString()).startOf('day');
      const endDate = startDate.endOf('day');
      onDateChange(startDate, endDate);
    } else {
      if (currentDateRangeInfo) {
        const { start, end } = getWeekRange(currentDateRangeInfo.start, weekStartDay);
        onDateChange(start, end);
      }
    }

    // onCalendarViewChange must be called AFTER the .changeView above or else the view will not change properly
    //  I expect this has to do with the calendar being re-rendered due to state change and interrupting the
    //  .changeView function
    onCalendarViewChange(view);
  }, [currentDateRangeInfo, getNow, onDateChange, onCalendarViewChange, weekStartDay]);

  const handleViewOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setViewOptionsAnchorEl(event.currentTarget);
  };
  const handleViewOptionsClose = () => {
    setViewOptionsAnchorEl(null);
  };

  const handleToggleMultiTimezone = onToggleMultiTimezone && (async () => {
    setLoadingViewOptions(true);
    await onToggleMultiTimezone();
    setLoadingViewOptions(false);
  });

  const handleToggleGranularTimeSelection = onToggleGranularTimeSelection && (async () => {
    setLoadingViewOptions(true);
    await onToggleGranularTimeSelection();
    setLoadingViewOptions(false);
  });

  return (
    <Box width="100%" height={HEADER_HEIGHT - 18} display="flex" justifyItems="space-between"
      paddingLeft={0.5} paddingRight={0.5}
    >
      <Box flex={1} display="flex" alignItems="center" gap={2}>
        <CabIcon Icon={FiSidebar} color={colors.purplePrimary} onClick={onToggleSidePanel} />

        <Box display="flex" alignItems="center" justifyContent={"space-between"} width={280}>
          <PrevNextArrows direction="back" onClick={prev} />
          <UserPrefCabDatePicker
            arrowInteractionOnly
            size="small"
            type={calendarView === RBCViews.WEEK ? 'week' : calendarView === RBCViews.WORK_WEEK ? 'work_week' : 'day'}
            value={middleOfInterval.toISOString()}
            onChange={onPickerDateChange}
            sx={{
              maxWidth: 100,
              width: '100px',
            }}
          />
          <PrevNextArrows direction="forward" onClick={next} />
        </Box>
      </Box>

      <Box display="flex" alignItems="center" gap={1}>
        <CabButton
          sx={{ 
            border: `1px solid ${colors.black200}`,
            color: colors.black900,
            fontWeight: 600,
            backgroundColor: colors.white900,
          }} 
          buttonType="text"
          size="small"
          onClick={e => onPickerDateChange(DateTime.now().toISODate())}
        >
          Today
        </CabButton>

        <CabButton
          buttonType="text"
          id="view-options-button"
          aria-controls={open ? 'view-options-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleViewOptionsClick}
          endIcon={<CabIcon Icon={IoChevronDown} sx={{ width: '14px' }} />}
          sx={{
            border: `1px solid ${colors.black200}`,
            color: colors.black900,
            fontWeight: 600,
            width: 120,
            justifyContent: 'space-between',
            paddingLeft: 1.5, paddingRight: 1,
            backgroundColor: colors.white900,
          }}
          size="small"
        >
          {calendarView === RBCViews.DAY ? 'Day' : calendarView === RBCViews.WORK_WEEK ? 'Work Week' : 'Week'}
        </CabButton>
        <Menu
          id="view-options-menu"
          anchorEl={viewOptionsAnchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          onClose={handleViewOptionsClose}
          MenuListProps={{ 'aria-labelledby': 'view-options-button' }}
        >
          <MenuItem onClick={() => handleChangeView(RBCViews.WORK_WEEK)} dense>
            <ListItemText
              primaryTypographyProps={{
                color: calendarView === RBCViews.WORK_WEEK ? colors.lavendar700 : 'inherit',
                fontWeight: 600,
              }}
            >
              Work Week
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleChangeView(RBCViews.WEEK)} dense>
            <ListItemText
              primaryTypographyProps={{
                color: calendarView === RBCViews.WEEK ? colors.lavendar700 : 'inherit',
                fontWeight: 600,
              }}
            >
              Week
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => handleChangeView(RBCViews.DAY)} dense>
            <ListItemText
              primaryTypographyProps={{
                color: calendarView === RBCViews.DAY ? colors.lavendar700 : 'inherit',
                fontWeight: 600,
              }}
            >
              Day
            </ListItemText>
          </MenuItem>
          
          <MenuItem sx={{ borderBottom: `1px solid ${colors.black200}`, pointerEvents: 'none', padding: 0.5 }} />

          <MenuItem onClick={onToggleShowPendingSlots} dense>
            <CabCheckbox
              checked={showPendingSlots} onChange={onToggleShowPendingSlots}
              sx={{ marginRight: 2 }}
            />
            <ListItemText>Show Pending Slots</ListItemText>
          </MenuItem>
          <MenuItem onClick={onToggleCondensePendingSlots} dense>
            <CabCheckbox
              checked={condensePendingSlots} onChange={onToggleCondensePendingSlots}
              sx={{ marginRight: 2 }}
            />
            <ListItemText>
              Condense Pending Slots
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={onToggleShowAllRecurringSlots} dense>
            <CabCheckbox
              checked={showAllRecurringSlots}
              onChange={onToggleShowAllRecurringSlots}
              sx={{ marginRight: 2 }}
            />
            <ListItemText>
              Show All Recurring Slots
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={onToggleShowAllDayEvents} dense>
            <CabCheckbox
              checked={showAllDayEvents} onChange={onToggleShowAllDayEvents}
              sx={{ marginRight: 2 }}
            />
            <ListItemText>
              Show All Day Events
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={handleToggleGranularTimeSelection} dense>
            <CabCheckbox
              checked={granularTimeSelection} onChange={handleToggleGranularTimeSelection}
              sx={{ marginRight: 2 }}
            />
            <ListItemText>
              Granular Time Selection
            </ListItemText>
          </MenuItem>

          <MenuItem onClick={onToggleCanceledDeclinedMeetings} dense>
            <CabCheckbox
              checked={showCanceledDeclinedMeetings} onChange={onToggleCanceledDeclinedMeetings}
              sx={{ marginRight: 2 }}
            />
            <ListItemText>
              Show Declined Events
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={hasMultiTimezone ? handleToggleMultiTimezone : openUpgradeModal}
            disabled={loadingViewOptions} dense>
            <CabCheckbox
              checked={showSecondaryTimezone}
              onChange={handleToggleMultiTimezone}
              disabled={!hasMultiTimezone}
              sx={{ marginRight: 2 }}
            />
            <ListItemText>
              <Box display='flex' gap={1}>
                <Typography variant='body2' color={!hasMultiTimezone ? colors.black600 : 'unset'}>
                  Show Multiple Timezones
                </Typography>
                {!hasMultiTimezone && (
                  <CabIcon
                    Icon={IoLockClosedOutline}
                    alt='Locked'
                    slot='end'
                    sx={{ fontSize: 19 }}
                  />
                )}
              </Box>
            </ListItemText>
          </MenuItem>
          {hasMultiLeaderCalendarView && (
            <CabTooltip wrapWithSpan
              title={calendarView === RBCViews.DAY ? 'This option is always on for Day View' : ''}
            >
              <MenuItem
                onClick={onToggleShowMultiLeaderColumns}
                disabled={calendarView === RBCViews.DAY}
                dense
              >
                <CabCheckbox
                  checked={showMultiLeaderColumns || calendarView === RBCViews.DAY}
                  disabled={calendarView === RBCViews.DAY}
                  onChange={onToggleShowMultiLeaderColumns}
                  sx={{ marginRight: 2 }}
                />
                <ListItemText>
                  Split Teammate View
                </ListItemText>
              </MenuItem>
            </CabTooltip>
          )}
        </Menu>


        {!creatingMeeting && !currentMeetingId && (
          <CreateMeetingButton
            buttonTitle="Create"
            buttonId="ScheduleHeader-create-meeting-btn"
            onCreateMeetingOverride={onCreateMeeting}
            onCreateReusableMeetingOverride={onCreateReusableMeeting}
            onCreatePollOverride={onCreatePoll}
          />
        )}
      </Box>
    </Box>
  );
};

export default ScheduleHeader;


interface PrevNextArrowsProps { 
  direction: 'forward'|'back';
  onClick: () => void;
  disabled?: boolean;
  sx?: SxProps;
}

const PrevNextArrows = ({ direction, onClick, disabled, sx }: PrevNextArrowsProps) => (
  <CabIcon
    Icon={direction === 'forward' ? IoChevronForward : IoChevronBack}
    alt={direction}
    onClick={disabled ? undefined : onClick}
    sx={{
      width: '40px',
      minWidth: '40px',
      display: 'flex',
      justifyContent: 'center',
      fontSize: '28px',
      color: disabled ? colors.black300 : colors.black600,
      ...sx
    }}
  />
);

