import { memo, ReactElement, useState } from 'react'; 
import { Box } from '@mui/material';
import { CAB_PANEL_WIDTH } from '@CabComponents/CabPanel';
import {
  MeetingSlot, MeetingHoldEventErrorResolution, Meeting, Calendar, InvalidParticipantVotes, MeetingHoldEventError,
} from '../../store';
import MeetingSettingsContainer from '../../components/Schedule/MeetingSettings';
import CabinetPage from '../../components/Common/CabinetPage';
import { CabinetModal } from '../../components/Common/CabinetModal';
import MeetingErrors from '../../components/Schedule/MeetingErrors';
import Header from './Header';
import CalendarSchedulerContainer from '../../components/Schedule/CalendarScheduler';
import ScheduleShareModalContainer from '../../components/Schedule/ScheduleShareModal';
import colors from '../../colors';
import DuplicateMeetingModal, {
  DuplicateMeetingSubmitProps
} from '../../components/Schedule/DuplicateMeetingModal/DuplicateMeetingModal';
import DeleteMeetingModal from '../../components/Schedule/DeleteMeetingModal';
import ReusableMeetingModal from '../../components/Schedule/ReusableMeetingModal';
import CabSpinner from '@CabComponents/CabSpinner';
import { ExcludedSlotInfo } from '../../utils/scheduleUtils';
import { SlotInfo } from 'react-big-calendar';
import SidePanel from './SidePanel';


const calendarSchedulerSx = { padding: 0, paddingTop: 0 };


interface ScheduleProps {
  currentMeeting: Meeting | null;
  currentMeetingErrors: MeetingHoldEventError[];
  errorModalOpen: boolean;
  handleResolution: (resolution: MeetingHoldEventErrorResolution) => void;
  calendars: Calendar[];
  handleCancel: () => void;
  handleOpenMeeting: (meetingId: number) => void;
  eventsExists: boolean;
  loadingEvents: boolean;
  allLoaded: boolean;
  openMeetingSettings: boolean;
  hasGrant: boolean;
  selectedSlots: MeetingSlot[];
  recurringSlots: MeetingSlot[];
  handleCreateSlot: (info: SlotInfo) => void;
  handleEditSlot: (eventId: string, start: Date, end: Date, isExcluded: boolean) => void;
  handleDeleteSlots: (slots: MeetingSlot[]) => void;
  handleDuplicateMeeting: (meetingId: number) => void;
  handleDeleteMeeting: (meetingId: number) => void;
  handleShareMeeting: (meetingId: number) => void;
  handleCopyLink: (meetingId: number) => void;
  handleConvertToOneOff: (meetingId: number) => void;
  currentMeetingSlots: MeetingSlot[];
  updateSlotNames: (name: string) => void;
  setErrorModalOpen: (open: boolean) => void;
  handleShareCurrentMeeting: () => void;
  updatingSlots: boolean;
  pollUpdateOpen: boolean;
  handlePollUpdateAccept: () => void;
  handlePollUpdateReject: () => void;
  invalidatedParticipantVotes: InvalidParticipantVotes;
  handleCreateExcludeSlots: (slots: ExcludedSlotInfo[]) => void;
  shareMeetingModalMeetingId: number;
  setShareMeetingModalMeetingId: (id: number) => void;
  setDuplicateMeetingModalMeetingId: (id: number) => void;
  handleSubmitDuplicateMeeting: (data: DuplicateMeetingSubmitProps) => Promise<Meeting | undefined>;
  setDeleteMeetingModalMeetingId: (id: number) => void;
  handleSubmitDeleteMeeting: (meeting: Meeting) => void;
  meetingToDuplicate?: Meeting;
  meetingToDelete?: Meeting;
}

const Schedule = ({
  currentMeeting, currentMeetingErrors, errorModalOpen, handleResolution, calendars,
  handleCancel, handleOpenMeeting, eventsExists, loadingEvents, allLoaded, openMeetingSettings, hasGrant,
  selectedSlots, recurringSlots, handleCreateSlot, handleEditSlot, handleDeleteSlots, handleDuplicateMeeting,
  handleDeleteMeeting, handleShareMeeting, handleCopyLink, handleConvertToOneOff, currentMeetingSlots, updateSlotNames,
  setErrorModalOpen, handleShareCurrentMeeting, updatingSlots, pollUpdateOpen,
  handlePollUpdateAccept, handlePollUpdateReject, invalidatedParticipantVotes, handleCreateExcludeSlots,
  shareMeetingModalMeetingId, setShareMeetingModalMeetingId, setDuplicateMeetingModalMeetingId,
  handleSubmitDuplicateMeeting, setDeleteMeetingModalMeetingId, handleSubmitDeleteMeeting, meetingToDuplicate,
  meetingToDelete,
}: ScheduleProps): ReactElement => {
  const [openSidePanel, setOpenSidePanel] = useState(true);

  const handleToggleSidePanel = () => {
    setOpenSidePanel(!openSidePanel);
  };

  return (
    <CabinetPage
      pageName={'Schedule'}
      // headerBackgroundColor={colors.calmGrey50}
      headerBackgroundColor={colors.white900}
      headerContent={<>
        <CabinetModal
          open={currentMeetingErrors.length > 0 ? errorModalOpen : false}
          component={
            <MeetingErrors
              meeting={currentMeeting}
              meetingErrors={currentMeetingErrors}
              handleResolution={handleResolution}
              calendars={calendars}
            />
          }
          onClose={() => setErrorModalOpen(false)}         
        />
        <Box
          width="100%"
          // for open side panel offset
          marginRight={openMeetingSettings ? `${CAB_PANEL_WIDTH}px` : 0 }
        >
          <Header onToggleSidePanel={handleToggleSidePanel} />
        </Box>
      </>}
    >
      {((!eventsExists && loadingEvents) || !allLoaded) && (
        <Box sx={{
          position: 'absolute',
          zIndex: 200,
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <CabSpinner scale={4} />
        </Box>
      ) }

      <Box display="flex" flex={1} height="100%">
        <Box
          display="flex"
          flex={1}
          flexDirection="row"
          gap={1}
          // for open side panel offset
          marginRight={openMeetingSettings ? 0 : `-${CAB_PANEL_WIDTH}px`}
        >
          <SidePanel
            open={openSidePanel}
            onCancel={() => setOpenSidePanel(false)}
          />

          <CalendarSchedulerContainer
            userHasGrant={hasGrant}
            handleOpenMeeting={handleOpenMeeting}
            selectedSlots={selectedSlots}
            recurringSlots={recurringSlots}
            handleSlotsCreated={handleCreateSlot}
            handleEditSlot={handleEditSlot}
            handleDeleteSlots={handleDeleteSlots}
            handleDuplicateMeeting={handleDuplicateMeeting}
            handleDeleteMeeting={handleDeleteMeeting}
            handleShareMeeting={handleShareMeeting}
            handleCopyLink={handleCopyLink}
            hideCalendarHeader={true}
            sx={calendarSchedulerSx}
          />
        </Box>
        {currentMeeting?.use_template_parent && currentMeeting.template_parent ? (
          <ReusableMeetingModal
            open={!!currentMeeting}
            meeting={currentMeeting}
            onClose={handleCancel}
            onConvertToOneOff={() => handleConvertToOneOff(currentMeeting.id)}
            onEditReusable={() => currentMeeting.template_parent && handleOpenMeeting(currentMeeting.template_parent)}
          />
        ) : (
          <MeetingSettingsContainer
            meetingSlots={currentMeetingSlots}
            onUpdateMeetingName={updateSlotNames}
            onEditSlot={handleEditSlot}
            onDeleteSlots={handleDeleteSlots}
            onShowErrors={setErrorModalOpen}
            meetingErrors={currentMeetingErrors}
            onShare={handleShareCurrentMeeting}
            onCancel={handleCancel}
            openMeetingSettings={openMeetingSettings}
            slotsAreRecalculating={updatingSlots}
            pollUpdateOpen={pollUpdateOpen}
            onPollUpdateAccept={handlePollUpdateAccept}
            onPollUpdateReject={handlePollUpdateReject}
            invalidMeetingSlots={invalidatedParticipantVotes}
            onExcludedSlotsCreated={handleCreateExcludeSlots}
          />
        )}
        <ScheduleShareModalContainer
          key={shareMeetingModalMeetingId}
          meetingId={shareMeetingModalMeetingId}
          onModalClose={() => setShareMeetingModalMeetingId(-1)}
          modalOpen={shareMeetingModalMeetingId > 0}
          showEdit
        />
        <DuplicateMeetingModal
          open={!!meetingToDuplicate}
          onClose={() => setDuplicateMeetingModalMeetingId(-1)}
          meeting={meetingToDuplicate}
          submitDuplicate={handleSubmitDuplicateMeeting}
        />
        <DeleteMeetingModal
          open={!!meetingToDelete}
          onClose={() => setDeleteMeetingModalMeetingId(-1)}
          meeting={meetingToDelete}
          submitDelete={handleSubmitDeleteMeeting}
        />
      </Box>
    </CabinetPage>
  );
};

export default memo(Schedule);
