import { ReactElement, memo, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SlotInfo } from "react-big-calendar";
import { SxProps } from "@mui/material";
import { Leader, MeetingSlot, RootState, ThunkDispatchType } from "../../../store";
import CalendarScheduler from "./CalendarScheduler";
import { PAGE_URL } from "../../../constants";
import { selectUserCalendarEvents } from "../../../store/schedule/selectors";
import { TimeZone } from "../../../utils/scheduleUtils";
import { router } from "../../../router";
import {
  calendarViewUpdated, selectActiveCalendars, selectAllHiddenCalendarIds, selectCalendarMap,
  selectSelectedAdditionalCalendars, selectSelectedLeaderCalendars, updateSecondaryTimezones,
  updateCalendarTimezone, selectDateRange, selectCurrentOrNewMeeting, updateDateRange,
  selectActiveTimezones,
} from "../../../store/scheduleUI";
import { useHandler } from "../../../store/hooks";


type Props =  {
  userHasGrant: boolean;
  handleOpenMeeting?: (meetingId: number) => void;
  selectedSlots: MeetingSlot[];
  recurringSlots: MeetingSlot[];
  handleSlotsCreated?: (info: SlotInfo, isExcluded?: boolean) => void;
  handleEditSlot?: (eventId: string, start: Date, end: Date, isExcluded: boolean) => void;
  handleDeleteSlots?: (slots: MeetingSlot[]) => void;
  handleDuplicateMeeting?: (meetingId: number) => void
  handleDeleteMeeting?: (meetingId: number) => void
  handleShareMeeting?: (meetingId: number) => void
  handleCopyLink?: (meetingId: number) => void
  hideCalendarHeader?: boolean;
  sx?: SxProps;
};

const CalendarSchedulerContainer = ({
  userHasGrant, handleOpenMeeting, selectedSlots, recurringSlots, handleSlotsCreated, handleEditSlot, handleDeleteSlots,
  handleDuplicateMeeting, handleDeleteMeeting, handleShareMeeting, handleCopyLink, hideCalendarHeader, sx,
}: Props): ReactElement => {
  const handle = useHandler();
  const dispatch = useDispatch<ThunkDispatchType>();
  const navigate = router.navigate;

  const user = useSelector((state: RootState) => state.auth.user);
  const leaders = useSelector((state: RootState) => state.leaders);
  const schedulingPrefs = useSelector((state: RootState) => state.schedule.schedulingPrefs);
  const newMeeting = useSelector((state: RootState) => state.schedule.newMeeting);
  const calendars = useSelector((state: RootState) => state.schedule.calendars);
  const meetings = useSelector((state: RootState) => state.schedule.meetings);
  const currentOrNewMeeting = useSelector(selectCurrentOrNewMeeting);
  const calendarMap = useSelector(selectCalendarMap);
  const additionalCalendars = useSelector(selectSelectedAdditionalCalendars);
  const selectedLeaderCalendars = useSelector(selectSelectedLeaderCalendars);
  const activeCalendars = useSelector(selectActiveCalendars);
  const hiddenCalendarIds = useSelector(selectAllHiddenCalendarIds);
  const showCanceledDeclinedMeetings = useSelector((state: RootState) => state.scheduleUI.showCanceledDeclinedMeetings);
  const showPendingSlots = useSelector((state: RootState) => state.scheduleUI.showPendingSlots);
  const condensePendingSlots = useSelector((state: RootState) => state.scheduleUI.condensePendingSlots);
  const showAllDayEvents = useSelector((state: RootState) => state.scheduleUI.showAllDayEvents);
  const showMultiLeaderColumns = useSelector((state: RootState) => state.scheduleUI.showMultiLeaderColumns);
  const calendarView = useSelector((state: RootState) => state.scheduleUI.calendarView);
  const calendarsLoaded = useSelector((state: RootState) => state.schedule.calendarsLoaded);
  const leadersLoaded = useSelector((state: RootState) => state.leaders.loaded);
  const associationsLoaded = useSelector((state: RootState) => state.schedule.associationsLoaded);
  const meetingsLoaded = useSelector((state: RootState) => state.schedule.meetingsLoaded);
  const [calendarTimezone, ...secondaryTimezones] = useSelector(selectActiveTimezones);
  const currentDateRangeInfo = useSelector(selectDateRange);
  const selectedLeaders = useSelector((state: RootState) => state.scheduleUI.selectedLeaderIds);

  const leaderHasAssociations = selectedLeaderCalendars.length > 0;

  const visibleDisplayCalendars = useMemo(() => (
    activeCalendars.filter(c => !hiddenCalendarIds.includes(c.id)).map(c => c.calendar_id)
  ), [activeCalendars, hiddenCalendarIds]);

  const consolidatedEvents = useSelector((state: RootState) => selectUserCalendarEvents(
    state,
    new Set(visibleDisplayCalendars),
    currentDateRangeInfo.start.toISO() || undefined,
    currentDateRangeInfo.end.toISO() || undefined,
    showCanceledDeclinedMeetings,
  ));

  const currentMeeting = currentOrNewMeeting?.id ? meetings[currentOrNewMeeting.id] : null;
  const showSecondaryTimezone = user?.features.MULTI_TIMEZONE && schedulingPrefs.user_prefs?.multi_timezone;
  const currentMeetingDurationMinutes = currentOrNewMeeting ? currentOrNewMeeting.duration_minutes : undefined;
  const meetingPreventDoubleBooking = currentOrNewMeeting?.prevent_conflict;

  const openAssociationModal = () => navigate(PAGE_URL.MANAGE_CALENDARS);
  const openAccountManagementModal = () => navigate(PAGE_URL.INTEGRATION_SETTINGS);
  const handleOpenPollResults = (handleOpenPollResultsId: number) => (
    navigate(`${PAGE_URL.POLL_RESULTS}/${handleOpenPollResultsId}/`)
  );

  const handleSecondaryTimezoneSelected = useCallback((idx: number, timezone: TimeZone) => {
    const newTimezones = [
      ...secondaryTimezones.slice(0, idx), timezone, ...secondaryTimezones.slice(idx + 1)
    ];
    dispatch(updateSecondaryTimezones(newTimezones.filter(tz => tz != null)));
  }, [dispatch, secondaryTimezones]);

  return (
    <CalendarScheduler
      selectedLeaders={selectedLeaders.map(lId => leaders.leaders.find(l => l.id === lId))
        .filter((leader): leader is Leader => !!leader)}
      calendars={calendars}
      meetings={meetings}
      calendarMap={calendarMap}
      additionalCalendars={additionalCalendars}
      newMeeting={newMeeting}
      coloredEvents={consolidatedEvents}
      showSecondaryTimezone={showSecondaryTimezone}
      currentMeetingDurationMinutes={currentMeetingDurationMinutes}
      meetingPreventDoubleBooking={meetingPreventDoubleBooking}
      granularTimeSelection={schedulingPrefs.user_prefs?.granular_time_selection || false}
      leaderHasAssociations={leaderHasAssociations}
      userHasGrant={userHasGrant}
      openAssociationModal={openAssociationModal}
      openAccountManagementModal={openAccountManagementModal}
      currentDateRangeInfo={currentDateRangeInfo}
      handleOpenMeeting={handleOpenMeeting}
      selectedSlots={selectedSlots}
      recurringSlots={recurringSlots}
      handleSlotsCreated={handleSlotsCreated}
      handleEditSlot={handleEditSlot}
      onDateChange={handle(updateDateRange)}
      calendarTimezoneSelected={calendarTimezone}
      handleCalendarTimezoneSelected={handle(updateCalendarTimezone)}
      secondaryTimezonesSelected={secondaryTimezones}
      handleSecondaryTimezoneSelected={handleSecondaryTimezoneSelected}
      handleDeleteSlots={handleDeleteSlots}
      currentMeetingId={currentMeeting?.id}
      isPoll={currentOrNewMeeting?.is_poll}
      handleDuplicateMeeting={handleDuplicateMeeting}
      handleDeleteMeeting={handleDeleteMeeting}
      handleShareMeeting={handleShareMeeting}
      handleCopyLink={handleCopyLink}
      onOpenPollResults={handleOpenPollResults}
      showPendingSlots={showPendingSlots}
      condensePendingSlots={condensePendingSlots}
      showAllDayEvents={showAllDayEvents}
      showMultiLeaderColumns={showMultiLeaderColumns}
      hideCalendarHeader={hideCalendarHeader}
      noCalendarBorder
      sx={sx}
      calendarView={calendarView}
      onCalendarViewChange={handle(calendarViewUpdated)}
      calendarsLoaded={calendarsLoaded}
      leadersLoaded={leadersLoaded}
      meetingsLoaded={meetingsLoaded}
      associationsLoaded={associationsLoaded}
      weekStartDay={schedulingPrefs.user_prefs?.week_start_day}
    />
  );
};

export default memo(CalendarSchedulerContainer);
