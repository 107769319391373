import { CabButton } from "@CabComponents/CabButton";
import { CabIcon } from "@CabComponents/CabIcon";
import { CabTimePicker } from "@CabComponents/CabTimePicker";
import { Box, IconButton, Typography } from "@mui/material";
import { IoTrashOutline } from 'react-icons/io5';
import { DateTime } from "luxon";
import { ReactElement, useEffect, useMemo } from "react";
import { Controller, useFieldArray, useForm, useWatch } from "react-hook-form";
import colors from "../../../colors";
import { ExcludedSingleDateFormData, MeetingSlot } from "../../../store";
import { 
  convertExcludedSingleDateFormDataToMeetingSlots, convertMeetingSlotsToExcludedSingleDateFormData, TimeZone 
} from "../../../utils/scheduleUtils";
import UserPrefCabDatePicker from "@CabComponents/CabDatePicker/UserPrefDatePicker";

export interface SingleDateExcludedTimesProps {
  excludedMeetingSlots: MeetingSlot[];
  meetingId: number;
  timezone: TimeZone | undefined;
  setExcludedSlots: (slots: MeetingSlot[]) => void;
  onDelete: (slot: MeetingSlot[]) => void;
  onEditSlot: (eventId: string, start: Date, end: Date, isExcluded: boolean) => void;
}

const SingleDateExcludedTimes = ({
  excludedMeetingSlots, onDelete, timezone, setExcludedSlots, meetingId, onEditSlot
}: SingleDateExcludedTimesProps): ReactElement => {
  const meetingSlotData = useMemo(() => { 
    return convertMeetingSlotsToExcludedSingleDateFormData(excludedMeetingSlots);
  }, [excludedMeetingSlots]);

  const {
    control, watch
  } = useForm<{excludedSlots:ExcludedSingleDateFormData[]}>({
    defaultValues: {excludedSlots: meetingSlotData}
  });
  const { fields, append, remove, update } = useFieldArray({control, name: 'excludedSlots'});

  const defaultExcludedTimes: ExcludedSingleDateFormData = {
    slotId: -1,
    date: DateTime.now().toISO(),
    startTime: '12:00',
    endTime: '13:00',
  };

  const excludedSlotsFormData = useWatch({name: 'excludedSlots', control});
  const currentSlotData = watch('excludedSlots');

  const deleteAndRemove = (index: number, data: ExcludedSingleDateFormData) => {
    remove(index);
    const slotArr = convertExcludedSingleDateFormDataToMeetingSlots([data], meetingId);
    if (slotArr[0].id !== -1) {
      onDelete(slotArr);
    }
  };

  const editExistingSlot = (data: ExcludedSingleDateFormData) => {
    const meetingSlotArr = convertExcludedSingleDateFormDataToMeetingSlots([data], meetingId);
    const slot = meetingSlotArr[0];
    if (slot.end_date > slot.start_date) {
      onEditSlot(String(slot.id), new Date(slot.start_date), new Date(slot.end_date), true);
    }
  };

  useEffect(() => {
    for (let i = 0; i < currentSlotData.length; i++) {
      const slot = currentSlotData[i];
      if (slot.startTime > slot.endTime) {
        update(i, {endTime: slot.startTime, date: slot.date, startTime: slot.startTime, slotId: slot.slotId});
      }
    }
  }, [currentSlotData, excludedSlotsFormData, update]);

  useEffect(() => {
    const meetingSlots = convertExcludedSingleDateFormDataToMeetingSlots(excludedSlotsFormData, meetingId);
    setExcludedSlots(meetingSlots);
  }, [excludedSlotsFormData, meetingId, setExcludedSlots]);

  return (
    <form>
      <Box display='flex' flexDirection='column' gap={2}>
        {fields.map((excludedSlot, index) => {
          return (
            <Box display='flex' gap={5} paddingRight={3} key={excludedSlot.id}>
              <Controller
                name={`excludedSlots.${index}.date`}
                defaultValue={excludedSlot.date}
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <Box display='flex' gap={4}>
                    <UserPrefCabDatePicker
                      {...field}
                      sx={{width: 150}}
                      onUpdate={() => excludedSlot.slotId !== -1 ? 
                        editExistingSlot(currentSlotData[index]) : undefined}
                    />
                    
                  </Box>
                )}/>
              <Box width="100%" display="flex" flexDirection="row" gap={1} alignItems="center" paddingRight={3}>
                <Controller
                  name={`excludedSlots.${index}.startTime`}
                  defaultValue={excludedSlot.startTime}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, ...field }}) => (
                    <>
                      <CabTimePicker {...field} pickerTime={excludedSlot.startTime} fullWidth
                        onUpdate={() => excludedSlot.slotId !== -1 ? 
                          editExistingSlot(currentSlotData[index]) : undefined}
                        maxTime={excludedSlot.slotId !== -1 ? currentSlotData[index].endTime : undefined}
                      />
                    </>
                  )}
                />
                <Typography variant="h5"> - </Typography>
                <Controller
                  name={`excludedSlots.${index}.endTime`}
                  defaultValue={excludedSlot.endTime}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, ...field }}) => (
                    <>
                      <CabTimePicker {...field} pickerTime={excludedSlot.endTime} fullWidth
                        onUpdate={() => excludedSlot.slotId !== -1 ? 
                          editExistingSlot(currentSlotData[index]) : undefined}
                        minTime={currentSlotData[index].startTime}
                      />
                    </>
                  )}
                />
              </Box>
              <Box display='flex' alignContent='center'>
                <IconButton edge='end' aria-label='remove' disableRipple sx={{padding: 0}}
                  onClick={() => deleteAndRemove(index, excludedSlotsFormData[index])}
                >
                  <CabIcon Icon={IoTrashOutline} alt='Remove' size='large' sx={{color: colors.black900 }} />
                </IconButton>
              </Box>
            </Box>
          );
        })}
        <Box>
          <CabButton
            buttonType="tertiary"
            color='accent'
            onClick={() => append(defaultExcludedTimes)}
            sx={{marginTop: 1}}
          >
            Add Excluded Time
          </CabButton>
        </Box>
      </Box>
    </form>
  );
};

export default SingleDateExcludedTimes;