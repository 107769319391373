import { CabDropdown } from "@CabComponents/CabDropdown";
import { CabIcon } from "@CabComponents/CabIcon";
import { CabList } from "@CabComponents/CabList";
import { CabTooltip } from "@CabComponents/CabTooltip";
import { Box, Alert, Link } from "@mui/material";
import { IoLockClosedOutline } from 'react-icons/io5';
import { ReactElement } from "react";
import { Control, Controller, UseFormReturn } from "react-hook-form";
import colors from "../../../colors";
import { Meeting, MeetingHoldEventError } from "../../../store";
import { MeetingSettingFormInput } from "./MeetingSettings";


interface ExecutiveHoldControlProps {
  control: Control<MeetingSettingFormInput>;
  meeting: Partial<Meeting> & { id: Meeting['id'] };
  isOwner: boolean;
  showHolds?: boolean;
  silenceHolds: boolean;
  openUpgradeModal: () => void;
  setExecutiveHoldAlertOpen: (cal: number | null) => void;
  meetingForm: UseFormReturn<MeetingSettingFormInput>;
  calendarOptions: { id: number; calendar_id: string; name: string, can_edit?: boolean }[];
  meetingErrors: MeetingHoldEventError[];
  onShowErrors: (show: boolean) => void;
}

const ExecutiveHoldControl = ({
  control, meeting, isOwner, showHolds, silenceHolds, openUpgradeModal, setExecutiveHoldAlertOpen, meetingForm,
  calendarOptions, meetingErrors, onShowErrors
}: ExecutiveHoldControlProps): ReactElement => {
  return (
    <Box marginTop={0.5} id='ExecutiveHoldControl-toggle'>
      <Controller name="executiveHoldCalendars" control={control} render={({ field }) => (
        <>
          {calendarOptions.filter(cal => field.value.includes(cal.id)).length ? (
            <CabList
              items={calendarOptions
                .filter(cal => field.value.includes(cal.id)).map(({ id, name }) => ({
                  value: id,
                  label: name,
                  // icon,
                  editable: !meetingForm.formState.isSubmitting,
                  onDeleteClick: () => field.onChange(field.value.filter(v => v !== id)),
                }))}
            />
          ) : <></>}
          <CabTooltip
            title={calendarOptions.length === 0
              ? "You don't have the ability to create events on any calendars for the selected executives"
              : ''
            }
            placement="left"
            wrapWithSpan
          >
            <Box display='flex' alignItems='center' gap={2} onClick={!showHolds ? openUpgradeModal : undefined}
              sx={{cursor: 'pointer'}}>
              {!showHolds && (
                <CabIcon
                  Icon={IoLockClosedOutline}
                  alt='Locked'
                  slot='end'
                  sx={{ fontSize: 22, marginLeft: 1 }}
                />
              )}
              <CabDropdown<number | string>
                placeholder={calendarOptions.filter(cal => !field.value.includes(cal.id)).length === 0
                  ? 'No available calendars' 
                  : "Add calendar for holds"}
                placeholderColor={colors.black500}
                disabled={!showHolds || calendarOptions.filter(cal => !field.value.includes(cal.id)).length === 0}
                size='small'
                options={calendarOptions
                  .filter(cal => !field.value.includes(cal.id))
                  .map(({ id, name }) => ({ value: id, label: name }))}
                onChange={silenceHolds || field.value.length > 0 ? (e) => field.onChange(
                  Array.from(new Set([...field.value, e.target.value as number]))
                ) : (e) => setExecutiveHoldAlertOpen(e.target.value as number)}
                value={''}
                sx={{ width: '100%', marginTop: 1, marginBottom: 1, fontColor: 'red' }}
              />
            </Box>
          </CabTooltip>
        </>
      )} />
      {/* )} */}

      {meetingErrors.length > 0 &&
        <Alert variant="outlined" severity="error" sx={{ marginTop: 2 }}>
          {meeting.error_count} errors occured with your meeting hold.&nbsp;
          <Link component="button" onClick={() => onShowErrors(true)}>View Errors</Link>
        </Alert>
      }
    </Box>
  );
};

export default ExecutiveHoldControl;