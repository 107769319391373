import { Control, Controller, useWatch } from "react-hook-form";
import { MeetingSettingFormInput } from "./MeetingSettings";
import { ReactElement, useState } from "react";
import { CabModal } from "@CabComponents/CabModal";
import { CabButton } from "@CabComponents/CabButton";
import { Alert, Box, FormControl, FormLabel } from "@mui/material";
import { CabTextTokenInput, TemplateVars, transformMarkupToText } from "@CabComponents/CabTextTokenInput";
import { Meeting } from "../../../store";
import { CabTextInput } from "@CabComponents/CabTextInput";
import { CabDurationPicker } from "@CabComponents/CabDurationPicker";
import { CabTooltip } from "@CabComponents/CabTooltip";
import { CabIcon } from "@CabComponents/CabIcon";
import { IoHelpCircleOutline } from 'react-icons/io5';
import colors from "../../../colors";


interface EditMeetingInfoModalProps {
  open: boolean;
  control: Control<MeetingSettingFormInput>;
  isFormValid: boolean;
  isPoll: boolean;
  onCancel: () => void;
  onSubmit: () => Promise<void>
  showTokenInputs: boolean;
  meeting: Partial<Meeting> & { id: Meeting['id'] };
  templateVars: TemplateVars;
  templateAddQuestionButtonClicked: () => void;
  descriptionDisabled: boolean;
}

const EditMeetingInfoModal = ({
  open, control, isFormValid, onCancel, isPoll, onSubmit, showTokenInputs, meeting, templateVars, 
  templateAddQuestionButtonClicked, descriptionDisabled
}: EditMeetingInfoModalProps): ReactElement => {
  const [errorMessage, setErrorMessage] = useState('');
  const meetingName = useWatch({control, name: "meetingName"});
  const validateNum = (attendees: number) => {
    if (attendees < 1) {
      setErrorMessage('Attendees must be greater than 0');
      return false;
    } else if (attendees >= Object.values(meeting.participants || {}).length) {
      setErrorMessage('');
      return true;
    } else {
      setErrorMessage(`You already have ${Object.values(meeting.participants || {}).length}
      attendees listed for this poll`);
      return false;
    }
  };
  return (
    <CabModal
      open={open}
      onClose={onCancel}
      title={isPoll ? 'Edit Poll Info' : 'Edit Meeting Info'}
      closeIcon
      sx={{
        '& .MuiDialogContent-root': {
          paddingBottom: 0
        },
        '& .MuiDialog-paper': {
          width: '100%',
          maxWidth: 690,
        },
      }}
      actionButtons={
        <>
          <CabButton buttonType="secondary" onClick={onCancel}>
            Cancel
          </CabButton>
          <CabButton disabled={!isFormValid} 
            onClick={onSubmit}>
            Save
          </CabButton>
        </>
      }
    >
      <Box display='flex' flexDirection='column' width='100%' gap={2}>
        {meeting.is_reusable && (
          <Box display="flex" flexDirection="row" gap={1} alignContent="center">
            <Controller name="internalMeetingLabel" control={control}
              render={({ field: { ref, ...field } }) => (
                <FormControl sx={{ width: '100%'}}>
                  <FormLabel>
                    Internal Meeting Label
                  </FormLabel>
                  <CabTextInput
                    {...field}
                    inputRef={ref}
                    placeholder={transformMarkupToText(meetingName || '')}
                    multiline
                    minRows={1}
                    maxRows={3}
                    formControlSx={{ flex: 1 }}
                    endIcon={
                      <CabTooltip
                        wrapWithSpan
                        title={'This label helps you organize your reusable meetings. It will not be on '
                        + 'any events, and your attendees will never see it.'}
                        sx={{alignSelf: 'center'}}
                      >
                        <CabIcon Icon={IoHelpCircleOutline} sx={{ fontSize: '20px' }} />
                      </CabTooltip>
                    }
                  />
                </FormControl>
              )} />
          </Box>
        )}

        <Controller name="meetingName" control={control} rules={{ required: true }} 
          render={({ field: { ref, ...field } }) => (
            showTokenInputs ? (
              <FormControl sx={{ width: '100%'}}>
                <FormLabel>
                  Event Title*
                </FormLabel>
                <CabTextTokenInput
                  {...field}
                  inputRef={ref}
                  sx={{width: '100%'}}
                  required
                  multiline
                  templateVars={templateVars}
                  tokenColor={colors.black300}
                  inputProps={{ style: { maxHeight: 75 } }}
                  onAddQuestion={(meeting.id > 0 && (meeting.is_reusable || meeting.is_poll)) ? 
                    () => templateAddQuestionButtonClicked()
                    : undefined}
                />
              </FormControl>
            ) : (
              <FormControl sx={{ width: '100%'}}>
                <FormLabel>
                  Event Title*
                </FormLabel>
                <CabTextInput
                  {...field}
                  inputRef={ref}
                  required
                />
              </FormControl>
            )
          )} />
        {!descriptionDisabled && (
          <Controller name="description" control={control} render={({ field: { ref, ...field } }) => (
            showTokenInputs ? (
              <FormControl sx={{ width: '100%'}}>
                <FormLabel>
                  Event Description
                </FormLabel>
                <CabTextTokenInput
                  {...field}
                  inputRef={ref}
                  multiline
                  minRows={3}
                  maxRows={10}
                  templateVars={templateVars}
                  tokenColor={colors.black300}
                  inputProps={{ style: { height: 75 } }}
                  onAddQuestion={(meeting.id > 0 && (meeting.is_reusable || meeting.is_poll)) ? 
                    () => templateAddQuestionButtonClicked()
                    : undefined}
                />
              </FormControl>
            ) : (
              <FormControl sx={{ width: '100%'}}>
                <FormLabel>
                  Event Description
                </FormLabel>
                <CabTextInput 
                  {...field} 
                  inputRef={ref} 
                  multiline 
                  minRows={3}
                  maxRows={6} 
                />
              </FormControl>
            )
          )} />
        )}
        <Box display='flex' gap={2}>
          <Controller name="duration" control={control} render={({ field: { ref, ...field } }) => (
            <FormControl>
              <FormLabel sx={{marginBottom: 1}}>
                Event Duration
              </FormLabel>
              <CabDurationPicker
                {...field}
                durationMinutes={field.value}
                sx={{ marginBottom: "12px" }}
              />
            </FormControl>
          )} />
          {isPoll && (
            <Controller name="attendees" control={control} 
              rules={{ required: true, validate: {attendeeCount: v => validateNum(v)} }}
              render={({ field: { ref, ...field }, fieldState: { error } }) => {
                return <FormControl>
                  <FormLabel sx={{marginBottom: 1}}>
                    # Expected Attendees*
                  </FormLabel>
                  <CabTextInput
                    {...field}
                    inputRef={ref}
                    size={"xsmall"}
                    type="number"
                    sx={{
                      // hide up down arrows
                      // Chrome, Safari, Edge, Opera
                      "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
                        WebkitAppearance: "none",
                        margin: 0,
                      },
                      // Firefox
                      'input[type=number]': {
                        MozAppearance: 'textfield',
                      },
                    }}
                    inputProps={{
                      sx: { paddingTop: "6px", paddingBottom: "6px", paddingLeft: 1 }
                    }}
                  />
                  {errorMessage && (
                    <Box maxWidth={'200px'}>
                      <Alert severity="warning" variant="outlined"
                        sx={{fontSize: '12px', padding: '4px', marginTop: '4px', 
                          "& .MuiAlert-icon": {
                            fontSize: 20
                          },
                          "& .MuiAlert-message": {
                            padding: 0
                          }}}>
                        {errorMessage}
                      </Alert>
                    </Box>
                  )}
                </FormControl>;
              }}
            />
          )}
        </Box>
      </Box>
    </CabModal>
  );
};

export default EditMeetingInfoModal;