import { ReactElement, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions, RootState, ThunkDispatchType, GlobalModalComponentName } from "../../../store";
import Header from "./Header";
import {
  showAllRecurringSlotsUpdated, showCanceledDeclinedMeetingsUpdated, calendarViewUpdated,
  selectTimezone, selectSelectedAdditionalCalendars, selectDateRange, selectCurrentOrNewMeeting,
  updateDateRange,
  showPendingSlotsUpdated,
  condensePendingSlotsUpdated,
  showMultiLeaderColumnsUpdated,
  showAllDayEventsUpdated
} from "../../../store/scheduleUI";
import { useHandler } from "../../../store/hooks";
import { calendarToParticipant } from "../../../utils/scheduleUtils";


export interface ScheduleHeaderProps {
  onToggleSidePanel: () => void;
}


const ScheduleHeaderContainer = ({
  onToggleSidePanel
}: ScheduleHeaderProps): ReactElement | null => {
  const user = useSelector((state: RootState) => state.auth.user);
  const schedulingPrefs = useSelector((state: RootState) => state.schedule.schedulingPrefs);
  const showAllRecurringSlots = useSelector((state: RootState) => state.scheduleUI.showAllRecurringSlots);
  const showCanceledDeclinedMeetings = useSelector((state: RootState) => state.scheduleUI.showCanceledDeclinedMeetings);
  const calendarView = useSelector((state: RootState) => state.scheduleUI.calendarView);
  const calendarTimezone = useSelector(selectTimezone);
  const selectedLeaders = useSelector((state: RootState) => state.scheduleUI.selectedLeaderIds);
  const selectedAdditionalCalendars = useSelector(selectSelectedAdditionalCalendars);
  const currentDateRange = useSelector(selectDateRange);
  const currentOrNewMeeting = useSelector(selectCurrentOrNewMeeting);
  const showPendingSlots = useSelector((state: RootState) => state.scheduleUI.showPendingSlots);
  const condensePendingSlots = useSelector((state: RootState) => state.scheduleUI.condensePendingSlots);
  const showAllDayEvents = useSelector((state: RootState) => state.scheduleUI.showAllDayEvents);
  const showMultiLeaderColumns = useSelector((state: RootState) => state.scheduleUI.showMultiLeaderColumns);
  const leadersLoaded = useSelector((state: RootState) => state.leaders.loaded);

  const dispatch = useDispatch<ThunkDispatchType>();
  const handle = useHandler();

  const openUpgradeModal = () => dispatch(actions.globalModal.openModal(GlobalModalComponentName.CABINET_PROMO));

  const showSecondaryTimezone = user?.features.MULTI_TIMEZONE && schedulingPrefs.user_prefs?.multi_timezone;
  const creatingMeeting = currentOrNewMeeting?.id === -1;

  const handleToggleMultiTimezone = async () => {
    if (schedulingPrefs.user_prefs) {
      await dispatch(actions.schedule.updateSchedulingPrefs({ 
        id: schedulingPrefs.user_prefs.id,
        multi_timezone: !schedulingPrefs.user_prefs?.multi_timezone,
      }));
    }
  };

  const handleToggleGranularTimeSelection = async () => {
    if (schedulingPrefs.user_prefs) {
      await dispatch(actions.schedule.updateSchedulingPrefs({ 
        id: schedulingPrefs.user_prefs.id,
        granular_time_selection: !schedulingPrefs.user_prefs?.granular_time_selection,
      }));
    }
  };

  const handleToggleCanceledDeclinedMeetings = useCallback(() => {
    dispatch(showCanceledDeclinedMeetingsUpdated(!showCanceledDeclinedMeetings));
  }, [dispatch, showCanceledDeclinedMeetings]);

  const handleToggleShowAllRecurringSlots = useCallback(() => {
    dispatch(showAllRecurringSlotsUpdated(!showAllRecurringSlots));
  }, [dispatch, showAllRecurringSlots]);

  const handleNewMeetingClick = useCallback((poll = false, reusable = false) => {
    if (!leadersLoaded) return;
    dispatch(actions.schedule.createNewMeeting(
      selectedLeaders,
      selectedAdditionalCalendars.map((cal, idx) => calendarToParticipant(-1, cal, (idx + 1) * -1)),
      poll,
      reusable,
    ));
  }, [selectedAdditionalCalendars, dispatch, selectedLeaders, leadersLoaded]);

  const handleCreateOneOffMeeting = useCallback(() => {
    handleNewMeetingClick();
  }, [handleNewMeetingClick]);

  const handleCreatePoll = useCallback(() => {
    handleNewMeetingClick(true);
  }, [handleNewMeetingClick]);

  const handleCreateReusableMeeting = useCallback(() => {
    handleNewMeetingClick(false, true);
  }, [handleNewMeetingClick]);

  const handleToggleShowPendingSlots = useCallback(() => {
    dispatch(showPendingSlotsUpdated(!showPendingSlots));
  }, [dispatch, showPendingSlots]);

  const handleToggleCondensePendingSlots = useCallback(() => {
    dispatch(condensePendingSlotsUpdated(!condensePendingSlots));
  }, [dispatch, condensePendingSlots]);

  const handleToggleShowAllDayEvents = useCallback(() => {
    dispatch(showAllDayEventsUpdated(!showAllDayEvents));
  }, [dispatch, showAllDayEvents]);

  const handleToggleShowMultiLeaderColumns = useCallback(() => {
    dispatch(showMultiLeaderColumnsUpdated(!showMultiLeaderColumns));
  }, [dispatch, showMultiLeaderColumns]);

  if (!user) return null;

  return (
    <Header
      creatingMeeting={creatingMeeting}
      currentMeetingId={currentOrNewMeeting?.id}
      onCreateMeeting={handleCreateOneOffMeeting}
      onCreateReusableMeeting={handleCreateReusableMeeting}
      onCreatePoll={handleCreatePoll}
      calendarTimezoneSelected={calendarTimezone}
      onDateChange={handle(updateDateRange)}
      currentDateRangeInfo={currentDateRange}
      onToggleSidePanel={onToggleSidePanel}
      onToggleMultiTimezone={handleToggleMultiTimezone}
      onToggleGranularTimeSelection={handleToggleGranularTimeSelection}
      onToggleCanceledDeclinedMeetings={handleToggleCanceledDeclinedMeetings}
      hasMultiLeaderCalendarView={user?.features.MULTI_LEADER_CALENDAR_VIEW}
      hasMultiTimezone={user?.features.MULTI_TIMEZONE}
      granularTimeSelection={schedulingPrefs.user_prefs?.granular_time_selection || false}
      openUpgradeModal={openUpgradeModal}
      showCanceledDeclinedMeetings={showCanceledDeclinedMeetings}
      showSecondaryTimezone={showSecondaryTimezone}
      showAllRecurringSlots={showAllRecurringSlots}
      onToggleShowAllRecurringSlots={handleToggleShowAllRecurringSlots}
      calendarView={calendarView}
      onCalendarViewChange={handle(calendarViewUpdated)}
      showPendingSlots={showPendingSlots}
      onToggleShowPendingSlots={handleToggleShowPendingSlots}
      condensePendingSlots={condensePendingSlots}
      onToggleCondensePendingSlots={handleToggleCondensePendingSlots}
      showAllDayEvents={showAllDayEvents}
      onToggleShowAllDayEvents={handleToggleShowAllDayEvents}
      showMultiLeaderColumns={showMultiLeaderColumns}
      onToggleShowMultiLeaderColumns={handleToggleShowMultiLeaderColumns}
      weekStartDay={schedulingPrefs.user_prefs?.week_start_day}
    />
  );
};

export default ScheduleHeaderContainer;