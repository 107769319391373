import { Fragment, useCallback, useMemo } from "react";
import {
  Box, Collapse, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography
} from "@mui/material";
import { DateTime } from "luxon";
import { CabCheckbox } from "@CabComponents/CabCheckbox";
import { Leader, Calendar, WeekStartDay } from "../../../store";
import chroma from "chroma-js";
import colors from "../../../colors";
import { CabIcon } from "@CabComponents/CabIcon";
import {
  IoAddOutline, IoChevronDownOutline, IoChevronUpOutline, IoEyeOffOutline, IoEyeOutline, IoWarning,
} from "react-icons/io5";
import { useState } from "react";
import AdditionalCalendarsModal from "../../../components/Schedule/AdditionalCalendarsModal";
import { View as RBCView, Views as RBCViews } from 'react-big-calendar';
import { nonReadDisableText } from "../../../utils/scheduleUtils";
import { CabTooltip } from "@CabComponents/CabTooltip";
import UserPrefCabDatePicker from "@CabComponents/CabDatePicker/UserPrefDatePicker";
import { WEEKDAY_TO_NUM_LUXON } from "../../../constants";


const sidePanelWidth = '275px';

interface SidePanelProps {
  open: boolean;
  onCancel: () => void;
  leaders: Leader[];
  selectedLeaders: number[];
  onLeaderSelect: (selection: { leaderId: number, selected: boolean }[]) => void;
  middleOfInterval: DateTime;
  onUpdateDateRange: (start: DateTime, end: DateTime) => void;
  hiddenCalendarIds: number[];
  onToggleCalendarVisibility: (payload: { calendarId: number, hidden: boolean }[]) => void;
  leaderIdCalendarMap: Record<number, Calendar[]>;
  leaderIdPrimaryCalendarMap: Record<number, Calendar | undefined>;
  additionalCalendars: (Calendar & { title: string; subtitle: string; calendar_access_id: number; })[];
  calendarView: RBCView;
  allLoaded: boolean;
  onCheckAdditionalCalendar: (payload: { calendarAccessId: number, selected: boolean }[]) => void;
  weekStartDay?: WeekStartDay | null;
}

const SidePanel = ({
  open, onCancel, leaders, selectedLeaders, onLeaderSelect, additionalCalendars, middleOfInterval, onUpdateDateRange,
  hiddenCalendarIds, onToggleCalendarVisibility, leaderIdCalendarMap, leaderIdPrimaryCalendarMap, calendarView,
  allLoaded, onCheckAdditionalCalendar, weekStartDay
}: SidePanelProps) => {
  const [openAdditionalCalendarsModal, setOpenAdditionalCalendarsModal] = useState(false);
  const [expandedLeaderIds, setExpandedLeaderIds] = useState<Set<number>>(new Set());

  const hiddenCalendarSet = useMemo(() => new Set(hiddenCalendarIds), [hiddenCalendarIds]);

  // additionally sort leaders first if they have a calendar
  const sortedLeaders = useMemo(() => {
    return leaders.toSorted((a, b) => {
      const aHasCalendar = leaderIdCalendarMap[a.id].length > 0;
      const bHasCalendar = leaderIdCalendarMap[b.id].length > 0;
      if (aHasCalendar && !bHasCalendar) {
        return -1;
      } else if (!aHasCalendar && bHasCalendar) {
        return 1;
      }
      return 0;
    });
  }, [leaders, leaderIdCalendarMap]);

  const leaderCalendarMapWithAccess = useMemo(() => {
    return Object.fromEntries(Object.entries(leaderIdCalendarMap).map(([leaderId, calendars]) => {
      const calendarsWithAccess = calendars.map(cal => ({
        ...cal,
        noAccessMessage: nonReadDisableText(cal.canEdit, cal.readable, cal.provider, cal.freeBusy),
      }));
      return [leaderId, calendarsWithAccess];
    }));
  }, [leaderIdCalendarMap]);

  const additionalCalendarsWithAccess = useMemo(() => {
    return additionalCalendars.map(calendar => ({
      ...calendar,
      noAccessMessage: nonReadDisableText(calendar.canEdit, calendar.readable, calendar.provider, calendar.freeBusy),
    }));
  }, [additionalCalendars]);

  const handleLeaderSelect = (selection: { leaderId: number, selected: boolean }[]) => {
    onLeaderSelect(selection);
  };

  const handleUpdateLeaderSelection = useCallback((ids: number[]): void => {
    onLeaderSelect(ids.map(id => ({ leaderId: id, selected: true })));
  }, [onLeaderSelect]);

  const handleCheckLeader = (leaderId: number, checked: boolean) => {
    handleLeaderSelect([{ leaderId, selected: checked }]);
  };

  const handleToggleCalendarVisibility = (calendarId: number) => {
    onToggleCalendarVisibility([{ calendarId, hidden: !hiddenCalendarSet.has(calendarId) }]);
  };

  const handleToggleLeaderExpansion = (leaderId: number) => {
    setExpandedLeaderIds(prev => {
      const newSet = new Set(prev);
      if (newSet.has(leaderId)) newSet.delete(leaderId);
      else newSet.add(leaderId);
      return newSet;
    });
  };

  const rangeType = calendarView === RBCViews.WEEK || calendarView === RBCViews.WORK_WEEK ? 'week' : 'day';
  const viewType = calendarView === RBCViews.WEEK ? 'week'
    : calendarView === RBCViews.WORK_WEEK ? 'work_week'
      : 'day';

  return (
    <Drawer
      open={open}
      onClose={onCancel}
      variant="persistent"
      anchor="left"
      sx={{
        width: open ? sidePanelWidth : 0,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: sidePanelWidth,
          position: 'relative',
          height: '100%',
          overflow: 'hidden',
          opacity: allLoaded ? 1 : 0.7,
          pointerEvents: allLoaded ? 'auto' : 'none',
          // backgroundColor: colors.calmGrey50,
        },
      }}
    >
      <Box sx={{ padding: 1.5 }}>
        <UserPrefCabDatePicker
          static
          noActionBar
          condensed
          size="small"
          type={viewType}
          value={middleOfInterval.toISO()}
          onChange={d => {
            if (d) {
              let dateSelected = DateTime.fromISO(d);
              if (
                viewType !== "day" &&
                dateSelected.weekday === WEEKDAY_TO_NUM_LUXON[weekStartDay || WeekStartDay.SUNDAY]
              ) {
                // This ensures we don't get the previous week by mistake.
                // For example, if you select Sunday the default startOf behavior is to select the previous Monday.
                dateSelected = dateSelected.plus({ days: 1 });
              }
              onUpdateDateRange(dateSelected.startOf(rangeType), dateSelected.endOf(rangeType));
            }
          }}
        />
      </Box>

      <Divider />

      <Box sx={{ /*backgroundColor: colors.calmGrey50,*/ height: '100%', overflow: 'auto' }}>
        <Box sx={{ padding: 2.5, paddingRight: 0.5, overflow: 'auto' }}>
          <Typography variant="h6" sx={{ fontSize: 14, fontWeight: 600, color: colors.black700 }}>
            Selected Teammates
          </Typography>
          {sortedLeaders.map(leader => (
            <Fragment key={leader.id}>
              <List dense disablePadding sx={{ paddingRight: 1 }}>
                <ListItem
                  disablePadding
                  secondaryAction={leaderCalendarMapWithAccess[leader.id].length > 0 && (
                    <Box className="collapsible-icon">
                      <CabIcon
                        Icon={expandedLeaderIds.has(leader.id) ? IoChevronUpOutline : IoChevronDownOutline}
                        onClick={() => handleToggleLeaderExpansion(leader.id)} />
                    </Box>
                  )}
                  sx={{
                    marginTop: 1.25,
                    '& .collapsible-icon': { opacity: expandedLeaderIds.has(leader.id) ? 1 : 0 },
                    '&:hover .collapsible-icon': { opacity: 1 },
                    '& .MuiListItemSecondaryAction-root': { right: 0 },
                    paddingLeft: 0.25,
                  }}

                >
                  <ListItemButton role={undefined} dense disableGutters
                    onClick={(e) => {
                      // Don't fire if click originated from checkbox
                      if (!(e.target as HTMLElement).closest('.MuiCheckbox-root')) {
                        handleToggleLeaderExpansion(leader.id);
                      }
                    }}
                    sx={{ paddingTop: 0, paddingBottom: 0 }}
                  >
                    <ListItemIcon sx={{ minWidth: 22, marginRight: 1 }}>
                      <CabCheckbox
                        checked={selectedLeaders.includes(leader.id)}
                        onChange={(e, checked) => handleCheckLeader(leader.id, checked)}
                        overrides={{ disableRipple: true }}
                        sx={
                          leaderIdPrimaryCalendarMap[leader.id] && {
                            '& .MuiSvgIcon-root': {
                              color: leaderIdPrimaryCalendarMap[leader.id]?.backgroundColor
                            },
                            '&.Mui-checked': {
                              '& .MuiSvgIcon-root': {
                                color: leaderIdPrimaryCalendarMap[leader.id]?.backgroundColor
                              }
                            },
                          }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={`${leader.first_name} ${leader.last_name}`} 
                      primaryTypographyProps={{
                        fontWeight: 500,
                      }}
                      sx={{ margin: 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              </List>

              <Collapse in={expandedLeaderIds.has(leader.id)}>
                <List dense disablePadding sx={{ paddingRight: 1 }}>
                  {leaderCalendarMapWithAccess[leader.id].map(calendar => (
                    <ListItem
                      disablePadding
                      secondaryAction={(
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          {calendar.noAccessMessage && (
                            <CabTooltip title={calendar.noAccessMessage} wrapWithSpan>
                              <CabIcon Icon={IoWarning} color="orange" sx={{ fontSize: 18 }} />
                            </CabTooltip>
                          )}
                          <Box id="calendar-eye-icon">
                            <CabIcon
                              Icon={hiddenCalendarSet.has(calendar.id) ? IoEyeOffOutline : IoEyeOutline}
                              onClick={() => handleToggleCalendarVisibility(calendar.id)}
                            />
                          </Box>
                        </Box>
                      )}
                      sx={{
                        '& #calendar-eye-icon': { opacity: hiddenCalendarSet.has(calendar.id) ? 1 : 0 },
                        '&:hover #calendar-eye-icon': { opacity: 1 },
                        opacity: hiddenCalendarSet.has(calendar.id) ? 0.5 : 1,
                        paddingLeft: 0.25,
                        '& .MuiListItemSecondaryAction-root': { right: 0 },
                      }}
                      key={calendar.id}
                    >
                      <ListItemButton dense disableGutters
                        sx={{
                          paddingTop: 0.25, paddingBottom: 0.25,
                          paddingRight: calendar.noAccessMessage ? '42px !important' : '25px !important',
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 22, marginRight: 0.5, marginLeft: '6px' }}>
                          <Box sx={{
                            width: 12,
                            height: 12,
                            borderRadius: '50%',
                            backgroundColor: calendar.backgroundColor
                              && chroma(calendar.backgroundColor).alpha(0.5).hex(),
                            border: `1px solid ${calendar.backgroundColor}`,
                          }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={calendar.summary}
                          primaryTypographyProps={{ 
                            sx: {
                              fontStyle: 'italic',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap'
                            }
                          }}
                          sx={{ margin: 0 }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Collapse>

            </Fragment>
          ))}

          <Typography
            variant="h6"
            sx={{ marginBottom: 0.5, marginTop: 2, fontSize: 14, fontWeight: 600, color: colors.black700 }}
          >
            Other Calendars
          </Typography>
          <List dense>
            {additionalCalendarsWithAccess.map(calendar => (
              <ListItem
                key={calendar.id}
                disablePadding
                secondaryAction={(
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {calendar.noAccessMessage && (
                      <CabTooltip title={calendar.noAccessMessage} wrapWithSpan>
                        <CabIcon Icon={IoWarning} color="orange" sx={{ fontSize: 18 }} />
                      </CabTooltip>
                    )}
                    <Box id="calendar-eye-icon">
                      <CabIcon
                        Icon={hiddenCalendarSet.has(calendar.id) ? IoEyeOffOutline : IoEyeOutline}
                        onClick={() => handleToggleCalendarVisibility(calendar.id)}
                      />
                    </Box>
                  </Box>
                )}
                sx={{
                  '& #calendar-eye-icon': { opacity: hiddenCalendarSet.has(calendar.id) ? 1 : 0 },
                  '&:hover #calendar-eye-icon': { opacity: 1 },
                  opacity: hiddenCalendarSet.has(calendar.id) ? 0.5 : 1,
                  paddingLeft: 0.25,
                  '& .MuiListItemSecondaryAction-root': { right: 0 },
                }}

              >
                <ListItemButton role={undefined} dense disableGutters>
                  <ListItemIcon sx={{ minWidth: 22, marginRight: 1 }}>
                    <CabCheckbox
                      checked={true}
                      onChange={(e, checked) => (
                        onCheckAdditionalCalendar([{ calendarAccessId: calendar.calendar_access_id, selected: checked}])
                      )}
                      overrides={{ disableRipple: true }}
                      sx={calendar.backgroundColor ? {
                        '& .MuiSvgIcon-root': {
                          color: calendar.backgroundColor
                        },
                        '&.Mui-checked': {
                          '& .MuiSvgIcon-root': {
                            color: calendar.backgroundColor
                          }
                        },
                      } : {}}
                    />
                  </ListItemIcon>
                  <ListItemText primary={calendar.title} sx={{ margin: 0 }} />
                </ListItemButton>
              </ListItem>
            ))}
            <ListItem disablePadding>
              <ListItemButton role={undefined} dense disableGutters
                onClick={() => setOpenAdditionalCalendarsModal(true)}
              >
                <ListItemIcon sx={{ minWidth: 22, marginRight: 1 }}>
                  <CabIcon Icon={IoAddOutline} />
                </ListItemIcon>
                <ListItemText primary="Add Calendar" primaryTypographyProps={{ fontWeight: 600 }} sx={{ margin: 0 }} />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Box>

      {openAdditionalCalendarsModal && (
        <AdditionalCalendarsModal
          isOpen={openAdditionalCalendarsModal} 
          onCancel={() => setOpenAdditionalCalendarsModal(false)}
          selectedLeaders={selectedLeaders}
          onSelectLeaders={handleUpdateLeaderSelection}
        />
      )}

    </Drawer>
  );
};

export default SidePanel;
