import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'; 
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import capitalize from 'lodash/capitalize';
import { useDebouncedCallback } from 'use-debounce';
import { SlotInfo } from 'react-big-calendar';
import { usePrevious } from 'react-use';
import fastq from 'fastq';
import {
  actions, MeetingSlot, RootState, ThunkDispatchType, MeetingHoldEventErrorResolution, Meeting,
  InvalidParticipantVotes, MeetingFilter, MeetingUpdate, Grant, MeetingHoldEventError, MeetingStatus,
} from '../../store';
import classes from './Schedule.module.css';
import { useMountEffect } from '../../utils/hooks';
import { PAGE_URL, PROVIDER, PROVIDER_BY_ID, PROVIDER_BY_NAME } from '../../constants';
import { 
  colorDisplayMeetingSlots, clearSelectedSlots, getInvalidParticipantVotes, getSlotCreateResult,
  getSlotEditResult, getSlotsDeleteResult, getMinSlotId, ExcludedSlotInfo, calendarToParticipant, getBookingLinkUrl,
} from '../../utils/scheduleUtils';
import { checkForCalendarGrants, getGrants } from '../../utils/authUtils';
import { DuplicateMeetingSubmitProps } from '../../components/Schedule/DuplicateMeetingModal/DuplicateMeetingModal';
import { selectEvents, selectUserRecurringMeetingSlots } from '../../store/schedule/selectors';
import { useLocation, useParams } from 'react-router';
import { clearNavigationState, router } from '../../router';
import { Clipboard } from '@capacitor/clipboard';
import Schedule from './Schedule';
import {
  currentMeetingSet, selectActiveCalendars, selectCurrentMeeting, selectCurrentOrNewMeeting,
  selectDateRange, selectSelectedAdditionalCalendars, selectTimezone,
} from '../../store/scheduleUI';


type SlotState = {createdSlots: MeetingSlot[], deletedSlots: MeetingSlot[], updatedSlots: MeetingSlot[]};
type SlotOpTaskData = MeetingSlot|MeetingSlot[]|MeetingSlot['id']|MeetingSlot['id'][];
type SlotOpTask<T extends SlotOpTaskData> = { slot: T, operationFn: (slot: T) => Promise<void> };

const slotOperationQueue = fastq.promise(
  <T extends SlotOpTaskData>(task: SlotOpTask<T>) => task.operationFn(task.slot),
  1,
);

type RouteParams = { meetingId?: string;  provider?: string; };


const ScheduleContainer = (): ReactElement => {
  const user = useSelector((state: RootState) => state.auth.user);
  const auth = useSelector((state: RootState) => state.auth);
  const calendars = useSelector((state: RootState) => state.schedule.calendars);
  const associationsLoaded = useSelector((state: RootState) => state.schedule.associationsLoaded);
  const meetings = useSelector((state: RootState) => state.schedule.meetings);
  const meetingSlots = useSelector((state: RootState) => state.schedule.meetingSlots);
  const meetingsLoaded = useSelector((state: RootState) => state.schedule.meetingsLoaded);
  const calendarsLoaded = useSelector((state: RootState) => state.schedule.calendarsLoaded);
  const newMeeting = useSelector((state: RootState) => state.schedule.newMeeting);
  const scheduleErrors = useSelector((state: RootState) => state.schedule.scheduleErrors);
  const slotsLoaded = useSelector((state: RootState) => state.schedule.slotsLoaded);
  const schedulingPrefs = useSelector((state: RootState) => state.schedule.schedulingPrefs);
  const calendarErrors = useSelector((state: RootState) => state.schedule.calendarErrors);
  const events = useSelector((state: RootState) => selectEvents(state));
  const selectedLeaders = useSelector((state: RootState) => state.scheduleUI.selectedLeaderIds);
  const currentMeeting = useSelector(selectCurrentMeeting);
  const currentOrNewMeeting = useSelector(selectCurrentOrNewMeeting);
  const selectedAdditionalCalendars = useSelector(selectSelectedAdditionalCalendars);
  const activeCalendars = useSelector(selectActiveCalendars);
  const currentDateRange = useSelector(selectDateRange);
  const calendarTimezone = useSelector(selectTimezone);
  const showAllRecurringSlots = useSelector((state: RootState) => state.scheduleUI.showAllRecurringSlots);
  const leadersLoaded = useSelector((state: RootState) => state.leaders.loaded);

  const dispatch = useDispatch<ThunkDispatchType>();
  
  const fetchMeetingSlots = useCallback((unscheduled: boolean) => 
    dispatch(actions.schedule.fetchMeetingSlots(unscheduled)), [dispatch]);
  const fetchSlotsForMeeting = useCallback((meetingId: string) => 
    dispatch(actions.schedule.fetchSlotsForMeeting(meetingId)), [dispatch]);
  const fetchSchedulingPreferences = useCallback(() => 
    dispatch(actions.schedule.fetchSchedulingPreferences()), [dispatch]);
  const startPollingMeetings = useCallback((query: Partial<MeetingFilter>) => 
    dispatch(actions.schedule.startPollingMeetings(query)), [dispatch]);
  const fetchMeeting = useCallback((meetingId: string) => 
    dispatch(actions.schedule.fetchMeeting(meetingId)), [dispatch]);
  const deleteNewMeeting = useCallback(() => dispatch(actions.schedule.deleteNewMeeting()), [dispatch]);
  const updateMeeting = useCallback((
    meeting: MeetingUpdate, timeSlots: MeetingSlot[], files?: File[] | undefined
  ) => dispatch(actions.schedule.updateMeeting(meeting, timeSlots, files)), [dispatch]);
  
  const updateTimeSlot = useCallback((timeSlot: MeetingSlot) =>
    dispatch(actions.schedule.updateTimeSlot(timeSlot)), [dispatch]);
  const deleteTimeSlot = useCallback((slot: MeetingSlot) => 
    dispatch(actions.schedule.deleteTimeSlot(slot)), [dispatch]);
  const batchDeleteTimeSlots = useCallback((slotIds: number[]) => 
    dispatch(actions.schedule.batchDeleteTimeSlots(slotIds)), [dispatch]);
  const batchCreateTimeSlots = useCallback((timeSlots: MeetingSlot[]) =>
    dispatch(actions.schedule.batchCreateTimeSlots(timeSlots)), [dispatch]);
  const handleMeetingSlotCalendarEventResolution = useCallback((args: MeetingHoldEventErrorResolution) =>
    dispatch(actions.schedule.handleMeetingSlotCalendarEventResolution(args)), [dispatch]);
  const logoutOAuth = useCallback((grant: Grant) => dispatch(actions.auth.logoutOAuth(grant)), [dispatch]);

  const setMeetingSlotCalendarEventError = useCallback((args: {
    meetingId: number;
    errors: MeetingHoldEventError[];
  }) => dispatch(actions.schedule.setMeetingSlotCalendarEventError(args)), [dispatch]);
  const duplicateMeeting = useCallback((meetingId: number, data: Partial<Meeting>) => 
    dispatch(actions.schedule.duplicateMeeting(meetingId, data)), [dispatch]);
  const deleteMeeting = useCallback((meeting: Meeting) => 
    dispatch(actions.schedule.deleteMeeting(meeting)), [dispatch]);
  const setNavbarExpanded = useCallback((expanded: boolean) => 
    dispatch(actions.cabUI.setCabNavbarExpanded(expanded)), [dispatch]);

  const params = useParams<RouteParams>();
  const location = useLocation();
  const navigate = router.navigate;

  const currentMeetingId = currentMeeting?.id;
  const meetingId = currentMeetingId || -1;

  const [selectedSlots, setSelectedSlots] = useState<MeetingSlot[]>(
    colorDisplayMeetingSlots(meetingSlots, [], meetings, currentMeetingId, classes.pattern, schedulingPrefs.user_prefs)
  );
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [/*loadingCalendars*/, setLoadingCalendars] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [pollUpdateOpen, setPollUpdateOpen] = useState(false);
  const [invalidatedParticipantVotes, setInvalidatedParticipantVotes] = useState<InvalidParticipantVotes>({});
  const [pendingSlotsToCreate, setPendingSlotsToCreate] = useState<MeetingSlot[]>([]);
  const [pendingSlotsToDelete, setPendingSlotsToDelete] = useState<MeetingSlot[]>([]);
  const [pendingSlotsToUpdate, setPendingSlotsToUpdate] = useState<MeetingSlot[]>([]);
  const [duplicateMeetingModalMeetingId, setDuplicateMeetingModalMeetingId] = useState<number>(-1);
  const [deleteMeetingModalMeetingId, setDeleteMeetingModalMeetingId] = useState<number>(-1);
  const [shareMeetingModalMeetingId, setShareMeetingModalMeetingId] = useState<number>(-1);
  const [updatingSlots, setUpdatingSlots] = useState(false);

  const previousMeetingId = usePrevious(currentOrNewMeeting?.id);
  const previousAutoMergeSlots = usePrevious(currentOrNewMeeting?.auto_merge_slots);
  const previousDuration = usePrevious(currentOrNewMeeting?.duration_minutes);

  const calendarDependencyParam = useMemo(() => (
    calendars.toSorted((a, b) => a.id - b.id)
      .map((cal) => ({ id: cal.id, calendar_id: cal.calendar_id, leaders: cal.leaders }))
      .map((cal) => [cal.id, cal.calendar_id, cal.leaders].join(";")).join(",")
  ), [calendars]);

  const userRecurringMeetingSlots = useSelector((s: RootState) => (
    selectUserRecurringMeetingSlots(
      s, selectedSlots,
      currentDateRange.start,
      currentDateRange.end,
      currentOrNewMeeting?.id
    )
  ));

  const recurringSlots = useMemo(() => (
    !showAllRecurringSlots && !currentOrNewMeeting?.id ? [] : userRecurringMeetingSlots
  ), [showAllRecurringSlots, currentOrNewMeeting?.id, userRecurringMeetingSlots]);

  const hasGrant = !!user && checkForCalendarGrants(user.oauth_grant_details);

  const allLoaded = useMemo(() => (
    !hasGrant || (associationsLoaded && meetingsLoaded && calendarsLoaded)
  ), [hasGrant, associationsLoaded, meetingsLoaded, calendarsLoaded]);

  const currentMeetingErrors = useMemo(() => (
    currentMeeting?.id ? scheduleErrors[currentMeeting.id] || [] : []
  ), [currentMeeting?.id, scheduleErrors]);

  const currentMeetingSlots = useMemo(
    () => selectedSlots.filter(slot => slot.meeting === meetingId || slot.meeting < 0),
    [selectedSlots, meetingId],
  );

  const openMeetingSettings = !!currentOrNewMeeting;

  const meetingToDuplicate: Meeting | undefined = useMemo(() => (
    meetings[duplicateMeetingModalMeetingId]
  ), [duplicateMeetingModalMeetingId, meetings]);

  const meetingToDelete: Meeting | undefined = useMemo(() => (
    meetings[deleteMeetingModalMeetingId]
  ), [deleteMeetingModalMeetingId, meetings]);

  // Create a stable dependency that only changes when relevant calendar properties change
  const activeCalendarDependencyParam = useMemo(() => (
    activeCalendars.toSorted((a, b) => a.id - b.id)
      .map(cal => `${cal.provider}:${cal.calendar_id}`).join(",")
  ), [activeCalendars]);

  // NOTE: activeCalendars will update after remote calendars are fetched with minor changes such as foregroundColor.
  // We do not want to trigger additional fetches due to these changes so we use a stable dependency for memoizing
  // the active google and ms calendars.
  const activeGoogleCalendars = useMemo(() => (
    activeCalendars.filter((cal) => cal.provider === PROVIDER.GOOGLE.id).map((cal) => cal.calendar_id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [activeCalendarDependencyParam]);

  const activeMSCalendars = useMemo(() => (
    activeCalendars.filter((cal) => cal.provider === PROVIDER.MICROSOFT.id).map((cal) => cal.calendar_id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [activeCalendarDependencyParam]);

  const eventsExists = useMemo(() => {
    return activeCalendars.map((cal) => (
      !!events.find((event) => {
        const eventStart = DateTime.fromISO(event.start);
        const eventEnd = DateTime.fromISO(event.end);
        return eventEnd >= currentDateRange.start &&
        eventStart <= currentDateRange.end &&
        event.calendarId === cal.calendar_id;
      })
    )).some(val => val);
  }, [currentDateRange.start, currentDateRange.end, activeCalendars, events]);

  // This debounce is because we are updating slots on a few criteria, some of which change simultaneously.
  //   E.g. If Meeting X with Exec A is selected, switching to Meeting Y with Exex B would trigger this
  //   update sequence twice consecutively since both the meeting and exec are changing.
  const updateSelectedSlotsDebounced = useDebouncedCallback(() => {
    if (newMeeting) {
      setSelectedSlots(colorDisplayMeetingSlots([...meetingSlots, ...selectedSlots.filter(s => s.id < 0)],
        selectedLeaders, meetings, currentMeetingId, classes.pattern, schedulingPrefs.user_prefs));
    } else {
      setSelectedSlots(colorDisplayMeetingSlots(meetingSlots,
        selectedLeaders, meetings, currentMeetingId, classes.pattern, schedulingPrefs.user_prefs));
    }
  }, 100);

  const handleNewMeetingClick = useCallback((poll = false, reusable = false) => {
    const newMtg = dispatch(actions.schedule.createNewMeeting(
      selectedLeaders,
      selectedAdditionalCalendars.map((cal, idx) => calendarToParticipant(-1, cal, (idx + 1) * -1)),
      poll,
      reusable,
    ));

    return newMtg;
  }, [selectedAdditionalCalendars, dispatch, selectedLeaders]);

  const refetchEvents = useCallback(async (
    startDate: DateTime, endDate: DateTime, forTimezone: string
  ) => {
    setLoadingEvents(true);

    await dispatch(actions.schedule.fetchEvents({
      googleCalendarIds: activeGoogleCalendars,
      microsoftCalendarIds: activeMSCalendars,
      startDate: startDate.toUTC().toString(),
      endDate: endDate.toUTC().toString(),
      forTimezone,
      clearCache: false,
    }));

    setLoadingEvents(false);

    // Once the current week's events are fetched, we can unblock the UI and prefetch next week's events
    const nextWeekStart = startDate.plus({days: 7});
    const nextWeekEnd = endDate.plus({days: 7});
    
    dispatch(actions.schedule.fetchEvents({
      googleCalendarIds: activeGoogleCalendars,
      microsoftCalendarIds: activeMSCalendars,
      startDate: nextWeekStart.toUTC().toString(),
      endDate: nextWeekEnd.toUTC().toString(),
      forTimezone,
      clearCache: false,
    }));
    
  }, [dispatch, activeGoogleCalendars, activeMSCalendars]);

  const recalculateSelectedSlots = () => {
    if (currentMeetingSlots.length > 0 && currentOrNewMeeting) {
      let newSlotInfo = getSlotsDeleteResult(currentMeetingSlots);
      
      currentMeetingSlots.forEach(slot => {
        const slotInfo: SlotInfo & { id: number } = {
          id: slot.id,
          start: DateTime.fromISO(slot.start_date).toJSDate(),
          end: DateTime.fromISO(slot.end_date).toJSDate(),
          slots: [],
          action: 'select',
        };
        
        // need to pass in the previously created slots during the function when we call this
        const slotCreateResult = getSlotCreateResult(
          slotInfo, slotInfo.id, currentOrNewMeeting, newSlotInfo.createdSlots, slot.is_exclude
        );

        newSlotInfo = [newSlotInfo, slotCreateResult].reduce((a, b) => ({
          createdSlots: [...a.createdSlots, ...b.createdSlots],
          deletedSlots: [...a.deletedSlots, ...b.deletedSlots],
          updatedSlots: [...a.updatedSlots, ...b.updatedSlots],
        }));

      });

      attemptSlotUpdates(newSlotInfo);
    }
  };

  const updateSlotNames = useCallback((newMeetingName: string) => {
    setSelectedSlots(selectedSlots.map(slot => ({
      ...slot,
      title: slot.meeting === currentMeetingId ? newMeetingName : slot.title,
    })));
  // NOTE: just for selectedSlots
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMeetingId, JSON.stringify(selectedSlots)]);

  const updateSlots = useCallback(async ({createdSlots, updatedSlots, deletedSlots}: SlotState) => {
    setUpdatingSlots(true);

    const slotsToDelete = deletedSlots.filter(slot => slot.id > 0);
    if (slotsToDelete.length === 1) {
      slotOperationQueue.push({ slot: slotsToDelete[0], operationFn: deleteTimeSlot });
    } else if (slotsToDelete.length > 1) {
      slotOperationQueue.push({ slot: slotsToDelete.map(slot => slot.id), operationFn: batchDeleteTimeSlots });
    }

    const slotsToUpdate = updatedSlots.filter(slot => slot.id > 0);
    slotsToUpdate.forEach(slot => {
      slotOperationQueue.push({ slot, operationFn: updateTimeSlot });
    });

    if (meetingId > 0 && createdSlots.length > 0) {
      slotOperationQueue.push({ slot: createdSlots, operationFn: batchCreateTimeSlots });
    }

    const nextSlots = selectedSlots
      .map(existingEvent => updatedSlots.find(updateSlot => updateSlot.id === existingEvent.id) || existingEvent)
      .filter(existingEvent => !deletedSlots.find(removeSlot => removeSlot.id === existingEvent.id))
      .concat(createdSlots);
    const coloredDisplayMeetingSlots = colorDisplayMeetingSlots(
      nextSlots, selectedLeaders, meetings, currentMeetingId, classes.pattern, schedulingPrefs.user_prefs
    );
    setSelectedSlots(coloredDisplayMeetingSlots);

    // TODO: there may still be an issue where this hangs... seems to happen when multiple queue pushes
    // happen above
    await slotOperationQueue.drained();

    setUpdatingSlots(false);
  }, [batchCreateTimeSlots, batchDeleteTimeSlots, currentMeetingId,
    deleteTimeSlot, meetingId, meetings, schedulingPrefs.user_prefs, selectedLeaders, selectedSlots, updateTimeSlot]);

  const validateSlotChanges = useCallback(({
    paramMeetingId, createdSlots, updatedSlots, deletedSlots, existingSlots
  }: {
    paramMeetingId: number, createdSlots: MeetingSlot[], updatedSlots: MeetingSlot[],
    deletedSlots: MeetingSlot[], existingSlots: MeetingSlot[]
  }) => {
    const meetingParam = meetings[paramMeetingId];

    if (meetingParam && meetingParam.is_poll) {
      const invalidVotesFound = getInvalidParticipantVotes(
        meetingParam, createdSlots, deletedSlots, updatedSlots, existingSlots
      );
      
      if (Object.keys(invalidVotesFound).length > 0) {
        setInvalidatedParticipantVotes(invalidVotesFound);
        setPendingSlotsToCreate(createdSlots);
        setPendingSlotsToDelete(deletedSlots);
        setPendingSlotsToUpdate(updatedSlots);
        setPollUpdateOpen(true);
      } else {
        return updateSlots({ createdSlots, deletedSlots, updatedSlots });
      }
    } else {
      return updateSlots({ createdSlots, deletedSlots, updatedSlots });
    }
    return Promise.resolve();
  }, [meetings, updateSlots]);

  const attemptSlotUpdates = useCallback((slotState: SlotState) => {
    return validateSlotChanges({
      ...slotState,
      existingSlots: selectedSlots,
      paramMeetingId: meetingId,
    });
  }, [meetingId, selectedSlots, validateSlotChanges]);

  const handleOpenMeeting = useCallback((handleOpenMeetingId: number): void => {
    navigate(`${PAGE_URL.SCHEDULE}/${handleOpenMeetingId}/`);
  }, [navigate]);

  const handleCancel = useCallback(() => {
    if (currentMeetingId) {
      navigate(PAGE_URL.SCHEDULE);
    } else {
      deleteNewMeeting();
    }
    setSelectedSlots(clearSelectedSlots(selectedSlots));
    clearNavigationState();
  }, [deleteNewMeeting, selectedSlots, currentMeetingId, navigate]);

  const handleResolution = useCallback((context: MeetingHoldEventErrorResolution) => {
    handleMeetingSlotCalendarEventResolution(context).then(res => {
      if (res) {
        if (meetingId > 0) {
          const schedErrors = scheduleErrors[meetingId].filter(
            error => error.meetingSlotCalendarEventId !== context.meetingSlotCalendarEventId
          );
          setMeetingSlotCalendarEventError(
            {
              errors: schedErrors,
              meetingId: meetingId
            }
          );
          if (schedErrors.length === 0) {
            setErrorModalOpen(false);
          }
        }
      }
    });
  }, [meetingId, scheduleErrors, setErrorModalOpen, handleMeetingSlotCalendarEventResolution,
    setMeetingSlotCalendarEventError]);

  const handleDuplicateMeeting = useCallback((handleDuplicateMeetingId: number) => {
    setDuplicateMeetingModalMeetingId(handleDuplicateMeetingId);
  }, []);

  const handleSubmitDuplicateMeeting = useCallback(async (data: DuplicateMeetingSubmitProps) => {
    const meeting = await duplicateMeeting(duplicateMeetingModalMeetingId, data);
    if (meeting) {
      navigate(`${PAGE_URL.SCHEDULE}/${meeting.id}/`);
      setDuplicateMeetingModalMeetingId(-1);
      setShareMeetingModalMeetingId(meeting.id);
      return meeting;
    }
    return undefined;
  }, [duplicateMeetingModalMeetingId, navigate, setDuplicateMeetingModalMeetingId, setShareMeetingModalMeetingId,
    duplicateMeeting]);

  const handleDeleteMeeting = useCallback((handleDeleteMeetingId: number) => {
    setDeleteMeetingModalMeetingId(handleDeleteMeetingId);
  }, []);

  const handleSubmitDeleteMeeting = useCallback(async (meeting: Meeting) => {
    await deleteMeeting(meeting);
    if (meeting.id === currentMeetingId) {
      navigate(PAGE_URL.SCHEDULE);
    }
    setDeleteMeetingModalMeetingId(-1);
  }, [currentMeetingId, deleteMeeting, navigate, setDeleteMeetingModalMeetingId]);

  const handleShareMeeting = useCallback((mId: number) => {
    setShareMeetingModalMeetingId(mId);
  }, []);

  const handleCopyLink = useCallback(async (copyLinkMeetingId: number) => {
    const mtg = meetings[copyLinkMeetingId];
    if (mtg) {
      await Clipboard.write({ string: getBookingLinkUrl(mtg) });
      dispatch(actions.globalMessage.sendMessage({
        timeout: 750, message: 'Copied', header: "",
        position: { vertical: 'top', horizontal: 'center' },
        active: true, severity: 'success', autoDismiss: true
      }));
    }
  }, [dispatch, meetings]);

  const handleShareCurrentMeeting = useCallback(() => {
    handleShareMeeting(currentMeetingId || -1);
  }, [handleShareMeeting, currentMeetingId]);

  const handlePollUpdateAccept = useCallback(() => {
    updateSlots({
      createdSlots: pendingSlotsToCreate,
      deletedSlots: pendingSlotsToDelete,
      updatedSlots: pendingSlotsToUpdate
    });
    setInvalidatedParticipantVotes({});
    setPollUpdateOpen(false);
  }, [pendingSlotsToCreate, pendingSlotsToDelete, pendingSlotsToUpdate, updateSlots]);

  const handlePollUpdateReject = useCallback(() => {
    setPendingSlotsToCreate([]);
    setPendingSlotsToDelete([]);
    setPendingSlotsToUpdate([]);
    setInvalidatedParticipantVotes({});
    setPollUpdateOpen(false);
  }, []);

  const handleCreateSlot = useCallback((info: SlotInfo) => {
    if (!leadersLoaded) return;
    // make sure we handle first slot selected when meeting panel is closed
    const mtg = currentOrNewMeeting || handleNewMeetingClick();
    // if an id is given, use it (useful for batch/re-creation). Otherwise generate one.
    const newSlotId = getMinSlotId(selectedSlots) - 1;
    attemptSlotUpdates(getSlotCreateResult(info, newSlotId, mtg, currentMeetingSlots, false));
  }, [attemptSlotUpdates, currentMeetingSlots, currentOrNewMeeting, handleNewMeetingClick, selectedSlots,
    leadersLoaded]);

  const handleCreateExcludeSlots = useCallback((slots: ExcludedSlotInfo[]) => {
    let newSlotId = getMinSlotId(selectedSlots) - 1;
    const mtg = currentOrNewMeeting;
    if (!mtg) return;
    // TODO: This is not an optimal strategy, we should not need to create a new 
    // variable to store slotState, but selectedSlot state is not updating between cycles.
    let slotState: SlotState = {createdSlots: [], deletedSlots: [], updatedSlots: []};
    slots.forEach(slot => {
      const slotCreateResult = getSlotCreateResult(slot, newSlotId, mtg, currentMeetingSlots, true);
      slotState = {
        createdSlots:[...slotState.createdSlots, ...slotCreateResult.createdSlots], 
        deletedSlots: [...slotState.deletedSlots, ...slotCreateResult.deletedSlots], 
        updatedSlots:[...slotState.updatedSlots, ...slotCreateResult.updatedSlots]
      };
      newSlotId--;
    });
    attemptSlotUpdates(slotState);
  }, [attemptSlotUpdates, currentMeetingSlots, currentOrNewMeeting, selectedSlots]);

  const handleEditSlot = useCallback((eventId: string, start: Date, end: Date, isExcluded: boolean) => {
    attemptSlotUpdates(getSlotEditResult(
      eventId, start, end, currentMeetingSlots, isExcluded, currentOrNewMeeting || undefined
    ));
  }, [attemptSlotUpdates, currentMeetingSlots, currentOrNewMeeting]);

  const handleDeleteSlots = useCallback((slots: MeetingSlot[]) => {
    attemptSlotUpdates(getSlotsDeleteResult(slots));
  }, [attemptSlotUpdates]);

  const handleConvertToOneOff = useCallback(async (handleConvertToOneOffMeetingId: number) => {
    await updateMeeting({ id: handleConvertToOneOffMeetingId, use_template_parent: false }, []);
  }, [updateMeeting]);

  // set currentMeetingId from url params and fetch if necessary
  useEffect(() => {
    const cmId = params.meetingId ? Number(params.meetingId) : null;
    if (cmId != currentMeetingId) {
      dispatch(currentMeetingSet(cmId));

      // make sure any valid meeting given in the url is loaded, fetch slots if meeting is scheduled
      if (cmId && !meetings[cmId]) {
        fetchMeeting(cmId.toString());
        fetchSlotsForMeeting(cmId.toString());
      } else if (cmId && meetings[cmId]?.status !== MeetingStatus.PENDING) {
        fetchSlotsForMeeting(cmId.toString());
      }
    }
  }, [params.meetingId, meetings, dispatch, currentMeetingId, fetchMeeting, fetchSlotsForMeeting]);

  // start/stop polling meetings on mount/unmount
  useMountEffect(() => {
    let stop = () => {
      return;
    };
    (async () => {
      stop = await startPollingMeetings({status: MeetingStatus.PENDING});
    })();

    return () => stop();
  });

  const hasActiveCalendars = activeCalendars.length > 0;

  // start/stop polling events
  useEffect(() => {
    let stop = () => {
      return;
    };
    (async () => {
      if (hasActiveCalendars) {
        const startStr = currentDateRange.start.toUTC().toString(); 
        const endStr = currentDateRange.end.toUTC().toString();

        stop = await dispatch(actions.schedule.startPollEvents(
          activeGoogleCalendars, activeMSCalendars,
          startStr, endStr, calendarTimezone.name || "UTC"
        ));
      }
    })();
    return () => stop();
  }, [currentDateRange.start, currentDateRange.end, hasActiveCalendars, dispatch,
    activeGoogleCalendars, activeMSCalendars, calendarTimezone.name
  ]);

  // Refetch events when the date range changes, timezone changes, or calendarDependencyParam changes
  useEffect(() => {
    if (hasActiveCalendars) {
      refetchEvents(currentDateRange.start, currentDateRange.end, calendarTimezone.name);
    }
  }, [hasActiveCalendars, currentDateRange.start, currentDateRange.end,
    calendarTimezone.name, calendarDependencyParam, refetchEvents]);

  // other mount/unmount effects
  useMountEffect(() => {
    setNavbarExpanded(false);
    fetchSchedulingPreferences();
    dispatch(actions.schedule.fetchZoomSettings());

    // If no meeting slots are loaded yet, load only unscheduled
    if (!slotsLoaded) {
      fetchMeetingSlots(true);
    }

    return () => {
      deleteNewMeeting();
      dispatch(currentMeetingSet(null));
    };
  });

  // fetch calendars and/or remote calendars if user grant changes
  useEffect(() => {
    if (user?.oauth_grant_details) {
      dispatch(actions.schedule.fetchCalendars());

      const hasGrantCheck = checkForCalendarGrants(user.oauth_grant_details);
      if (hasGrantCheck) {
        setLoadingCalendars(true);
        dispatch(actions.schedule.fetchRemoteCalendars())
          .then(() => setLoadingCalendars(false));
      }
    }
  }, [dispatch, user?.oauth_grant_details]);

  // handle initially opening correct meeting type based on navigation state parameter
  useEffect(() => {
    if (location.state?.initSchedule) {
      deleteNewMeeting();
      updateSelectedSlotsDebounced();
    } else if (location.state?.createMeeting) {
      handleNewMeetingClick();
      updateSelectedSlotsDebounced();
    } else if (location.state?.createMeetingPoll) {
      handleNewMeetingClick(true);
      updateSelectedSlotsDebounced();
    } else if (location.state?.createReusableMeeting) {
      handleNewMeetingClick(false, true);
      updateSelectedSlotsDebounced();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key, location.state?.initSchedule, location.state?.createMeeting,
    location.state?.createMeetingPoll, location.state?.createReusableMeeting]);

  // logout OAuth provider if we get a tokens_invalid error
  useEffect(() => {
    if (calendarErrors.some(error => error.name === 'tokens_invalid')) {
      const grants = getGrants(auth);
      const providerNames = calendarErrors.map(error => {
        // NOTE: This will log out all usernames for the provider. This will NOT work well when we allow
        //   multiple accounts for each provider to be authenticated simultanously. I.e. one error for one would
        //   log them ALL out.
        grants.forEach(grant => {
          const grantProvider = PROVIDER_BY_NAME[grant.provider];
          if (grantProvider.id === error.provider) {
            logoutOAuth(grant);
          }
        });
        return capitalize(PROVIDER_BY_ID[error.provider].name);
      }).join(', ');
      // TODO: would be good to have a global alert popup we can trigger so this is visible after navigation
      // setAccountManagementMessage('Please re-authenticate with ' + providerNames);
      console.log('Please re-authenticate with ' + providerNames);
      navigate(PAGE_URL.INTEGRATION_SETTINGS);
    } else {
      // setAccountManagementMessage('');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarErrors, logoutOAuth, navigate]);

  // update slots
  useEffect(() => {
    if (!updatingSlots && allLoaded) {
      // Combining meetingSlots and selectedSlots is required to prevent losing slots from unsaved meetings      
      updateSelectedSlotsDebounced();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMeetingId, Object.values(meetings).map(m => `${m.id}:${m.status}`).join(','),
    meetingSlots, updatingSlots, allLoaded, selectedLeaders, newMeeting]);

  useEffect(() => {
    if (associationsLoaded && calendars.length === 0) {
      navigate(PAGE_URL.MANAGE_CALENDARS);
    }
  }, [associationsLoaded, calendars.length, navigate]);

  useEffect(() => {
    if (!currentOrNewMeeting?.id || !previousMeetingId || previousMeetingId !== currentOrNewMeeting.id) return;
    if (previousAutoMergeSlots != null && currentOrNewMeeting?.auto_merge_slots != null
      && previousAutoMergeSlots !== currentOrNewMeeting?.auto_merge_slots && !currentOrNewMeeting.auto_merge_slots
    ) {
      recalculateSelectedSlots();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrNewMeeting?.auto_merge_slots, previousAutoMergeSlots, currentOrNewMeeting?.id, previousMeetingId]);

  useEffect(() => {
    if (!currentOrNewMeeting?.id || !previousMeetingId || previousMeetingId !== currentOrNewMeeting.id) return;
    if ((currentOrNewMeeting?.duration_minutes || 0) > 0 && previousDuration !== currentOrNewMeeting.duration_minutes
      && currentOrNewMeeting?.auto_merge_slots === false
    ) {
      recalculateSelectedSlots();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrNewMeeting?.duration_minutes, currentOrNewMeeting?.id, previousMeetingId]);

  return (
    <Schedule
      currentMeeting={currentMeeting}
      currentMeetingErrors={currentMeetingErrors}
      errorModalOpen={errorModalOpen}
      handleResolution={handleResolution}
      calendars={calendars}
      handleCancel={handleCancel}
      handleOpenMeeting={handleOpenMeeting}
      eventsExists={eventsExists}
      loadingEvents={loadingEvents}
      allLoaded={allLoaded}
      openMeetingSettings={openMeetingSettings}
      hasGrant={hasGrant}
      selectedSlots={selectedSlots}
      recurringSlots={recurringSlots}
      handleCreateSlot={handleCreateSlot}
      handleEditSlot={handleEditSlot}
      handleDeleteSlots={handleDeleteSlots}
      handleDuplicateMeeting={handleDuplicateMeeting}
      handleDeleteMeeting={handleDeleteMeeting}
      handleShareMeeting={handleShareMeeting}
      handleCopyLink={handleCopyLink}
      handleConvertToOneOff={handleConvertToOneOff}
      currentMeetingSlots={currentMeetingSlots}
      updateSlotNames={updateSlotNames}
      setErrorModalOpen={setErrorModalOpen}
      handleShareCurrentMeeting={handleShareCurrentMeeting}
      updatingSlots={updatingSlots}
      pollUpdateOpen={pollUpdateOpen}
      handlePollUpdateAccept={handlePollUpdateAccept}
      handlePollUpdateReject={handlePollUpdateReject}
      invalidatedParticipantVotes={invalidatedParticipantVotes}
      handleCreateExcludeSlots={handleCreateExcludeSlots}
      shareMeetingModalMeetingId={shareMeetingModalMeetingId}
      setShareMeetingModalMeetingId={setShareMeetingModalMeetingId}
      setDuplicateMeetingModalMeetingId={setDuplicateMeetingModalMeetingId}
      handleSubmitDuplicateMeeting={handleSubmitDuplicateMeeting}
      setDeleteMeetingModalMeetingId={setDeleteMeetingModalMeetingId}
      handleSubmitDeleteMeeting={handleSubmitDeleteMeeting}
      meetingToDuplicate={meetingToDuplicate}
      meetingToDelete={meetingToDelete}
    />
  );
};

export default ScheduleContainer;
